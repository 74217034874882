<template>
    <div class="warp"
         v-loading="Loading"
         element-loading-text="拼命加载中"
         element-loading-spinner="el-icon-loading"
         element-loading-background="rgba(0, 0, 0, 0.8)">
        <div class="main">
            <div class="tab tab-top">
                <div class="tab-item toptab" @click="category1 = 1" :class="{'active':category1 == 1}">集市</div>
                <div class="tab-item toptab" @click="category1 = 2" :class="{'active':category1 == 2}">我的</div>
                <p style="padding: 6px 6px; float: right;color:#131313;">
                <span>当前角色：{{ info.charac_name }}
                  <span style="cursor:pointer"
                  @click="LoginDisplay = true">《换绑》</span></span>
              </p>
            </div>
            <div class="page page-1" :class="{'active':category1 == 1}">
                <div style="margin-top:68px;padding:0px 6px;">
                    <el-row :gutter="12">
                        <!-- 这边 -->
                        <el-col :span="8">
                          <el-row :gutter="12">
                              <el-col :span="24" style="padding-left:0px;">
                                <div style="margin-top:64px;">
                                  <div style="text-align:center;font-weight: 700;"><span>我的<span v-if="info.config.source == 1">集币</span><span v-if="info.config.source == 2">点券</span><span v-if="info.config.source == 3">代币券</span></span></div>
                                  <div style="margin-top:28px;text-align:center;"><span style="font-size:20px;color: #f00;font-weight: 700;">{{info.user.money}}</span></div>
                                </div>
                              </el-col>
                              <el-col :span="24" style="padding-left:0px;">
                                <div style="margin-top:28px;margin-bottom:6px;">
                                  <div style="text-align:center;font-weight: 700;"><span>实时交易快播</span></div>
                                </div>
                                <div>
                                  <div style="height: 250px;overflow: auto;">
                                    <div v-if=" info.items.buylog.length > 0">
                                      <el-row :gutter="12" style="margin-top:6px;" v-for="(v, i) in info.items.buylog" :key="i">
                                        <el-col :span="5">
                                          <el-tooltip class="item" effect="dark" content=""  placement="right-start">
                                              <div slot="content" style="width:198px;">
                                                  <div v-if="v.type == 1">
                                                    <div>
                                                      <img v-if="v.ico_data != ''" :src="v.ico_data" style="width:28px;height:28px;">
                                                      <img v-else src="../../../public/static/plug/tian/static/images/6.png" style="width:28px;height:28px;">
                                                      <span  v-if="v.other.rarity == 0" style="margin-left:12px;color: #fff;">{{ v.name }}<span v-if="v.other.strengthening > 0">+{{ v.other.strengthening }}</span></span>
                                                      <span  v-else-if="v.other.rarity == 1" style="margin-left:12px;color: rgb(244 159 255)">{{ v.name }}<span v-if="v.other.strengthening > 0">+{{ v.other.strengthening }}</span></span>
                                                      <span v-else-if="v.other.rarity == 2" style="margin-left:12px;color: #844693">{{ v.name }}<span v-if="v.other.strengthening > 0">+{{ v.other.strengthening }}</span></span>
                                                      <span v-else-if="v.other.rarity == 3" style="margin-left:12px;color: rgb(225 0 255)">{{ v.name }}<span v-if="v.other.strengthening > 0">+{{ v.other.strengthening }}</span></span>
                                                      <span  v-else-if="v.other.rarity == 4" style="margin-left:12px;color: #dde4b6">{{ v.name }}<span v-if="v.other.strengthening > 0">+{{ v.other.strengthening }}</span></span>
                                                      <span  v-else-if="v.other.rarity == 5" style="margin-left:12px;color: #a7ad7a">{{ v.name }}<span v-if="v.other.strengthening > 0">+{{ v.other.strengthening }}</span></span>
                                                      <span  v-else-if="v.other.rarity == 6" style="margin-left:12px;color: #d04d02">{{ v.name }}<span v-if="v.other.strengthening > 0">+{{ v.other.strengthening }}</span></span>
                                                      <span  v-else-if="v.other.rarity == 7" style="margin-left:12px;color: #e6ff02">{{ v.name }}<span v-if="v.other.strengthening > 0">+{{ v.other.strengthening }}</span></span>
                                          
                                                    </div>
                                                    <div>
                                                      <p style="text-align: right;margin-top:20px;color:#9195ff;">
                                                        <span  v-if="v.other.rarity == 0" style="color: #fff;">普通</span>
                                                        <span  v-else-if="v.other.rarity == 1" style="color: rgb(244 159 255);">高级</span>
                                                        <span  v-else-if="v.other.rarity == 2" style="color: #844693;">稀有</span>
                                                        <span  v-else-if="v.other.rarity == 3" style="color: rgb(225 0 255);">神器</span>
                                                        <span  v-else-if="v.other.rarity == 4" style="color: #dde4b6;">史诗</span>
                                                        <span  v-else-if="v.other.rarity == 5" style="color: #a7ad7a;">勇者</span>
                                                        <span  v-else-if="v.other.rarity == 6" style="color: #d04d02;">传说</span>
                                                        <span  v-else-if="v.other.rarity == 7" style="color: #e6ff02;">神话</span>
                                                      </p>
                                                    </div>
                                                    <div>
                                                    <p style="text-align: right;">
                                                      <span  style="color: #fff;" v-if="v.other.items_type == 10">武器</span>
                                                      <span  style="color: #fff;" v-if="v.other.items_type == 11">称号</span>
                                                      <span  style="color: #fff;" v-if="v.other.items_type == 12">上衣</span>
                                                      <span  style="color: #fff;" v-if="v.other.items_type == 13">头肩</span>
                                                      <span  style="color: #fff;" v-if="v.other.items_type == 14">下衣</span>
                                                      <span  style="color: #fff;" v-if="v.other.items_type == 15">鞋子</span>
                                                      <span  style="color: #fff;" v-if="v.other.items_type == 16">腰带</span>
                                                      <span  style="color: #fff;" v-if="v.other.items_type == 17">项链</span>
                                                      <span  style="color: #fff;" v-if="v.other.items_type == 18">手镯</span>
                                                      <span  style="color: #fff;" v-if="v.other.items_type == 19">戒指</span>
                                                      <span  style="color: #fff;" v-if="v.other.items_type == 20">辅助装备</span>
                                                      <span  style="color: #fff;" v-if="v.other.items_type == 21">魔法石</span>
                                                    </p>
                                                  </div>
                                                  <div v-if="inArray(v.other.rarity,[2,3,4,5,6,7,8,9])">
                                                    <p style="text-align: right;margin-top: 20px;">
                                                      <span  style="color: #fff;" v-if="v.other.seal_flag == 1" >{{SealFlag(v.other.strengthening)}}</span>
                                                      <span  style="color: rgb(206 9 9);" v-else-if="v.other.seal_flag == 0">无法交易</span>
                                                    </p>
                                                  </div>
                                                  <div>
                                                    <p>
                                                      <span  v-if="v.other.maxlevel > 0" style="color: #fff;">等级{{v.other.maxlevel}}以上使用</span>
                                                      <span  v-else  style="color: #fff;">等级不限制</span>
                                                      <span  v-if="v.other.num == 1" style="color: #fff;float: right;">最下级</span>
                                                      <span  v-if="v.other.num == 2 || v.other.num == 696817050" style="color: #fff;float: right;">上级</span>
                                                      <span  v-if="v.other.num == 3 || v.other.num == 1553487634" style="color: #fff;float: right;">下级</span>
                                                      <span  v-if="v.other.num == 4 || v.other.num == 1294422825" style="color: #fff;float: right;">最上级</span>
                                                    </p>
                                                  </div>
                                                  <div v-if="v.other.growth > 0">
                                                    <p style="text-align: left;margin-top: 20px;">
                                                      <span v-if="v.other.growth == 1"  style="color: rgb(239 24 255);">增幅：异次元体力 + {{v.other.growth_fj}}</span>
                                                      <span v-if="v.other.growth == 2"  style="color: rgb(239 24 255);">增幅：异次元精神 + {{v.other.growth_fj}}</span>
                                                      <span v-if="v.other.growth == 3"  style="color: rgb(239 24 255);">增幅：异次元力量 + {{v.other.growth_fj}}</span>
                                                      <span v-if="v.other.growth == 4"  style="color: rgb(239 24 255);">增幅：异次元智力 + {{v.other.growth_fj}}</span>
                                                    </p>
                                                  </div>
                                                  <div v-if="v.other.forging >0">
                                                    <p style="text-align: left;margin-top: 10px;">
                                                      <span  style="color: rgb(246 153 253);">锻造第{{v.other.forging}}阶段：独立攻击 +{{6.2*v.other.forging}}</span>
                                                    </p>
                                                  </div>
                                                  <div v-if="v.other.extend_info > 0">
                                                    <p style="text-align: left;margin-top: 20px;">
                                                      <span   style="color:rgb(190 212 8);">附魔宝珠 : {{v.other.extend_name}}</span>
                                                    </p>
                                                  </div>
                                                    <div>
                                                      <p style="text-align: center;margin-top:20px;"><span style="color:rgb(112 110 116);">物品详情存在一定的数据差异，实际数据去自行到游戏中对比，物品购买后直接发送至您的邮箱中，请注意查收。</span></p>
                                                    </div>
                                                  </div>
                                                  <div v-if="v.type == 2">
                                                    <div>
                                                      <img v-if="v.ico_data != ''" :src="v.ico_data" style="width:28px;height:28px;">
                                                      <img v-else src="../../../public/static/plug/tian/static/images/6.png" style="width:28px;height:28px;">
                                                      <span  v-if="v.other.rarity == 0" style="margin-left:12px;color: #fff;">{{ v.name }}</span>
                                                      <span  v-else-if="v.other.rarity == 1" style="margin-left:12px;color: rgb(244 159 255)">{{ v.name }}</span>
                                                      <span v-else-if="v.other.rarity == 2" style="margin-left:12px;color: #844693">{{ v.name }}</span>
                                                      <span v-else-if="v.other.rarity == 3" style="margin-left:12px;color: rgb(225 0 255)">{{ v.name }}</span>
                                                      <span  v-else-if="v.other.rarity == 4" style="margin-left:12px;color: #dde4b6">{{ v.name }}</span>
                                                      <span  v-else-if="v.other.rarity == 5" style="margin-left:12px;color: #a7ad7a">{{ v.name }}</span>
                                                      <span  v-else-if="v.other.rarity == 6" style="margin-left:12px;color: #d04d02">{{ v.name }}</span>
                                                      <span  v-else-if="v.other.rarity == 7" style="margin-left:12px;color: #e6ff02">{{ v.name }}</span>
                                          
                                                    </div>
                                                    <div>
                                                      <p style="text-align: right;margin-top:20px;color:#9195ff;">
                                                        <span  v-if="v.other.rarity == 0" style="color: #fff;">普通</span>
                                                        <span  v-else-if="v.other.rarity == 1" style="color: rgb(244 159 255);">高级</span>
                                                        <span  v-else-if="v.other.rarity == 2" style="color: #844693;">稀有</span>
                                                        <span  v-else-if="v.other.rarity == 3" style="color: rgb(225 0 255);">神器</span>
                                                        <span  v-else-if="v.other.rarity == 4" style="color: #dde4b6;">史诗</span>
                                                        <span  v-else-if="v.other.rarity == 5" style="color: #a7ad7a;">勇者</span>
                                                        <span  v-else-if="v.other.rarity == 6" style="color: #d04d02;">传说</span>
                                                        <span  v-else-if="v.other.rarity == 7" style="color: #e6ff02;">神话</span>
                                                      </p>
                                                    </div>
                                                    <div>
                                                      <p>
                                                        <span v-if="v.type == 2">消耗品</span>
                                                        <span v-if="v.type == 3">材料</span>
                                                      </p>
                                                    </div>
                                                  
                                                  </div>
                                                  <div v-if="v.type == 3">
                                                    <div>
                                                      <img v-if="v.ico_data != ''" :src="v.ico_data" style="width:28px;height:28px;">
                                                      <img v-else src="../../../public/static/plug/tian/static/images/6.png" style="width:28px;height:28px;">
                                                      <span  v-if="v.other.rarity == 0" style="margin-left:12px;color: #fff;">{{ v.name }}</span>
                                                      <span  v-else-if="v.other.rarity == 1" style="margin-left:12px;color: rgb(244 159 255)">{{ v.name }}</span>
                                                      <span v-else-if="v.other.rarity == 2" style="margin-left:12px;color: #844693">{{ v.name }}</span>
                                                      <span v-else-if="v.other.rarity == 3" style="margin-left:12px;color: rgb(225 0 255)">{{ v.name }}</span>
                                                      <span  v-else-if="v.other.rarity == 4" style="margin-left:12px;color: #dde4b6">{{ v.name }}</span>
                                                      <span  v-else-if="v.other.rarity == 5" style="margin-left:12px;color: #a7ad7a">{{ v.name }}</span>
                                                      <span  v-else-if="v.other.rarity == 6" style="margin-left:12px;color: #d04d02">{{ v.name }}</span>
                                                      <span  v-else-if="v.other.rarity == 7" style="margin-left:12px;color: #e6ff02">{{ v.name }}</span>
                                          
                                                    </div>
                                                    <div>
                                                      <p style="text-align: right;margin-top:20px;color:#9195ff;">
                                                        <span  v-if="v.other.rarity == 0" style="color: #fff;">普通</span>
                                                        <span  v-else-if="v.other.rarity == 1" style="color: rgb(244 159 255);">高级</span>
                                                        <span  v-else-if="v.other.rarity == 2" style="color: #844693;">稀有</span>
                                                        <span  v-else-if="v.other.rarity == 3" style="color: rgb(225 0 255);">神器</span>
                                                        <span  v-else-if="v.other.rarity == 4" style="color: #dde4b6;">史诗</span>
                                                        <span  v-else-if="v.other.rarity == 5" style="color: #a7ad7a;">勇者</span>
                                                        <span  v-else-if="v.other.rarity == 6" style="color: #d04d02;">传说</span>
                                                        <span  v-else-if="v.other.rarity == 7" style="color: #e6ff02;">神话</span>
                                                      </p>
                                                    </div>
                                                    <div>
                                                      <p>
                                                        <span v-if="v.type == 2">消耗品</span>
                                                        <span v-if="v.type == 3">材料</span>
                                                      </p>
                                                    </div>
                                                  
                                                  </div>
                                                  <div v-if="v.type == 4">
                                                    <div>
                                                      <img src="../../../public/static/plug/tian/static/images/6.png" style="width:28px;height:28px;"/>
                                                      <span style="margin-left:12px;color:rgb(172 182 129);">{{ v.name }}</span>
                                                    </div>
                                                  </div>
                                                  <div v-if="v.type == 5">
                                                    <div>
                                                        <img :src="v.ioc" style="width:28px;height:28px;"/>
                                                        <span style="margin-left:12px;color:#d1ff0e;">{{ v.name }}</span>
                                                      </div>
                                                      <div>
                                                        <p style="text-align: right;margin-top:20px;color:#9195ff;"><span>礼包</span></p>
                                                      </div>
                                                      <div>
                                                        <p style="text-align: left;margin-top:10px;"><span style="color:#a179e2;">礼包内包含了以下内容</span></p>
                                                      </div>
                                                      <div  style="text-align: left;margin-top:10px;">
                                                        <p style="margin-bottom:6px;color:#a18989;" v-for="va, indx in v.code_data"  :key="indx">{{ va.name }}*{{ va.num }}</p>
                                                      </div>
                                                  </div>
                                              </div>

                                              <img v-if="v.type == 1 && v.ico_data != ''" :src="v.ico_data" style="width:28px;height:28px;">
                                              <img v-else-if="v.type == 2 && v.ico_data != ''" :src="v.ico_data" style="width:28px;height:28px;">
                                              <img v-else-if="v.type == 3 && v.ico_data != ''" :src="v.ico_data" style="width:28px;height:28px;">
                                              <img v-else-if="v.type == 4" src="../../../public/static/plug/tian/static/images/6.png" style="width:28px;height:28px;">
                                              <img v-else-if="v.type == 5" :src="v.ioc" style="width:28px;height:28px;">
                                              <img v-else src="../../../public/static/plug/tian/static/images/6.png" style="width:28px;height:28px;">
                                          </el-tooltip> 
                                        </el-col>
                                        <el-col :span="19" style="padding-left:0px;padding-right:0px;">
                                          <span class="task-title" style="margin-left:6px;position: relative;top: 6px;left: -10px;"><span v-if="v.type == 1" style="color:#f70000; ">(装备)</span><span v-else-if="v.type == 2" style="color:#f70000;">(消耗)</span><span v-else-if="v.type == 3" style="color:#f70000;">(材料)</span><span v-else-if="v.type == 4" style="color:#f70000;">(卡密)</span><span v-else-if="v.type == 5" style="color:#f70000;">(商城)</span>
                                              <span  style="color: rgb(86 32 32);">{{ v.name }}
                                                <span v-if="v.type == 1 && v.other.strengthening > 0">+{{ v.other.strengthening }}</span>
                                                <span v-else-if="v.type == 2 || v.type == 3">x{{ v.other.num }}</span>
                                              </span>
                                          </span>
                                        </el-col>
                                      </el-row>
                                     </div>
                                     <div v-else style="margin-top:118px;text-align:center;">
                                      <span>无数据</span>
                                    </div> 
                                  </div>
                                </div>
                              </el-col>
                          </el-row>
                        </el-col>
                        <!-- 右边 -->
                        <el-col :span="16">
                          <div class="tab tab-mid" style="display: inline-block;">
                            <div class="tab-item midtab"  v-for="(vcc, i) in info.config.open" :key="i"   @click="category2 = vcc" :class="{'active':category2 == vcc}">
                              <span v-if="vcc == 1">装备</span>
                              <span v-if="vcc == 2">消耗品</span>
                              <span v-if="vcc == 3">材料</span>
                              <span v-if="vcc == 4">CDK</span>
                              <span v-if="vcc == 5">官方商城</span>
                          </div>
                          </div>
                          <div style="margin-left:-7px;height:392px;overflow: auto;">
                            <!-- 装备 -->
                            <el-row :gutter="12"  v-show="category2 == 1">
                              <div v-if=" info.items.equipment.length > 0">
                                 <el-col :span="12" v-for="(v, i) in info.items.equipment" :key="i">
                                     <div style="background: #3e3e3e;height: 42px;padding: 4px 0px;margin-top:6px;cursor:pointer;">
                                      <el-row :gutter="12">
                                        <el-col :span="5">
                                          <el-tooltip class="item" effect="dark" content="" placement="top">
                                            <div slot="content" style="width:198px;">
                                                <div>
                                                  <img v-if="v.ico_data != ''" :src="v.ico_data" style="width:28px;height:28px;">
                                                 <img v-else src="../../../public/static/plug/tian/static/images/6.png" style="width:28px;height:28px;">
                                                  <span  v-if="v.other.rarity == 0" style="margin-left:12px;color: #fff;">{{ v.name }}<span v-if="v.other.strengthening > 0">+{{ v.other.strengthening }}</span></span>
                                                  <span  v-else-if="v.other.rarity == 1" style="margin-left:12px;color: rgb(244 159 255)">{{ v.name }}<span v-if="v.other.strengthening > 0">+{{ v.other.strengthening }}</span></span>
                                                  <span v-else-if="v.other.rarity == 2" style="margin-left:12px;color: #844693">{{ v.name }}<span v-if="v.other.strengthening > 0">+{{ v.other.strengthening }}</span></span>
                                                  <span v-else-if="v.other.rarity == 3" style="margin-left:12px;color: rgb(225 0 255)">{{ v.name }}<span v-if="v.other.strengthening > 0">+{{ v.other.strengthening }}</span></span>
                                                  <span  v-else-if="v.other.rarity == 4" style="margin-left:12px;color: #dde4b6">{{ v.name }}<span v-if="v.other.strengthening > 0">+{{ v.other.strengthening }}</span></span>
                                                  <span  v-else-if="v.other.rarity == 5" style="margin-left:12px;color: #a7ad7a">{{ v.name }}<span v-if="v.other.strengthening > 0">+{{ v.other.strengthening }}</span></span>
                                                  <span  v-else-if="v.other.rarity == 6" style="margin-left:12px;color: #d04d02">{{ v.name }}<span v-if="v.other.strengthening > 0">+{{ v.other.strengthening }}</span></span>
                                                  <span  v-else-if="v.other.rarity == 7" style="margin-left:12px;color: #e6ff02">{{ v.name }}<span v-if="v.other.strengthening > 0">+{{ v.other.strengthening }}</span></span>
                                      
                                                </div>
                                                <div>
                                                  <p style="text-align: right;margin-top:20px;color:#9195ff;">
                                                    <span  v-if="v.other.rarity == 0" style="color: #fff;">普通</span>
                                                    <span  v-else-if="v.other.rarity == 1" style="color: rgb(244 159 255);">高级</span>
                                                    <span  v-else-if="v.other.rarity == 2" style="color: #844693;">稀有</span>
                                                    <span  v-else-if="v.other.rarity == 3" style="color: rgb(225 0 255);">神器</span>
                                                    <span  v-else-if="v.other.rarity == 4" style="color: #dde4b6;">史诗</span>
                                                    <span  v-else-if="v.other.rarity == 5" style="color: #a7ad7a;">勇者</span>
                                                    <span  v-else-if="v.other.rarity == 6" style="color: #d04d02;">传说</span>
                                                    <span  v-else-if="v.other.rarity == 7" style="color: #e6ff02;">神话</span>
                                                  </p>
                                                </div>
                                                <div>
                                                  <p style="text-align: right;">
                                                    <span  style="color: #fff;" v-if="v.other.items_type == 10">武器</span>
                                                    <span  style="color: #fff;" v-if="v.other.items_type == 11">称号</span>
                                                    <span  style="color: #fff;" v-if="v.other.items_type == 12">上衣</span>
                                                    <span  style="color: #fff;" v-if="v.other.items_type == 13">头肩</span>
                                                    <span  style="color: #fff;" v-if="v.other.items_type == 14">下衣</span>
                                                    <span  style="color: #fff;" v-if="v.other.items_type == 15">鞋子</span>
                                                    <span  style="color: #fff;" v-if="v.other.items_type == 16">腰带</span>
                                                    <span  style="color: #fff;" v-if="v.other.items_type == 17">项链</span>
                                                    <span  style="color: #fff;" v-if="v.other.items_type == 18">手镯</span>
                                                    <span  style="color: #fff;" v-if="v.other.items_type == 19">戒指</span>
                                                    <span  style="color: #fff;" v-if="v.other.items_type == 20">辅助装备</span>
                                                    <span  style="color: #fff;" v-if="v.other.items_type == 21">魔法石</span>
                                                  </p>
                                                </div>
                                                <div v-if="inArray(v.other.rarity,[2,3,4,5,6,7,8,9])">
                                                  <p style="text-align: right;margin-top: 20px;">
                                                    <span  style="color: #fff;" v-if="v.other.seal_flag == 1" >{{SealFlag(v.other.strengthening)}}</span>
                                                    <span  style="color: rgb(206 9 9);" v-else-if="v.other.seal_flag == 0">无法交易</span>
                                                  </p>
                                                </div>
                                                <div>
                                                  <p>
                                                    <span  v-if="v.other.maxlevel > 0" style="color: #fff;">等级{{v.other.maxlevel}}以上使用</span>
                                                    <span  v-else  style="color: #fff;">等级不限制</span>
                                                    <span  v-if="v.other.num == 1" style="color: #fff;float: right;">最下级</span>
                                                    <span  v-if="v.other.num == 2 || v.other.num == 696817050" style="color: #fff;float: right;">上级</span>
                                                    <span  v-if="v.other.num == 3 || v.other.num == 1553487634" style="color: #fff;float: right;">下级</span>
                                                    <span  v-if="v.other.num == 4 || v.other.num == 1294422825" style="color: #fff;float: right;">最上级</span>
                                                  </p>
                                                </div>
                                                <div v-if="v.other.growth > 0">
                                                  <p style="text-align: left;margin-top: 20px;">
                                                    <span v-if="v.other.growth == 1"  style="color: rgb(239 24 255);">增幅：异次元体力 + {{v.other.growth_fj}}</span>
                                                    <span v-if="v.other.growth == 2"  style="color: rgb(239 24 255);">增幅：异次元精神 + {{v.other.growth_fj}}</span>
                                                    <span v-if="v.other.growth == 3"  style="color: rgb(239 24 255);">增幅：异次元力量 + {{v.other.growth_fj}}</span>
                                                    <span v-if="v.other.growth == 4"  style="color: rgb(239 24 255);">增幅：异次元智力 + {{v.other.growth_fj}}</span>
                                                  </p>
                                                </div>
                                                <div v-if="v.other.forging >0">
                                                  <p style="text-align: left;margin-top: 10px;">
                                                    <span  style="color: rgb(246 153 253);">锻造第{{v.other.forging}}阶段：独立攻击 +{{6.2*v.other.forging}}</span>
                                                  </p>
                                                </div>
                                                 <div v-if="v.other.extend_info > 0">
                                                  <p style="text-align: left;margin-top: 20px;">
                                                    <span   style="color:rgb(190 212 8);">附魔宝珠 : {{v.other.extend_name}}</span>
                                                  </p>
                                                </div>
                                                <div>
                                                  <p style="text-align: center;margin-top:20px;"><span style="color:rgb(112 110 116);">物品详情存在一定的数据差异，实际数据去自行到游戏中对比，物品购买后直接发送至您的邮箱中，请注意查收。</span></p>
                                                </div>
                                            </div>
                                            <img v-if="v.ico_data != ''" :src="v.ico_data" style="width:28px;height:28px;">
                                            <img v-else src="../../../public/static/plug/tian/static/images/6.png" style="width:28px;height:28px;">
                                          </el-tooltip> 
                                        </el-col>
                                        <el-col :span="19" style="padding-left:0px;">
                                          <div style="display: inline-block;">
                                            <span class="itme-title" v-if="v.other.rarity == 0" style="color: #fff;">{{ v.name }}<span v-if="v.other.strengthening > 0">+{{ v.other.strengthening }}</span></span>
                                            <span class="itme-title" v-else-if="v.other.rarity == 1" style="color: rgb(244 159 255)">{{ v.name }}<span v-if="v.other.strengthening > 0">+{{ v.other.strengthening }}</span></span>
                                            <span class="itme-title" v-else-if="v.other.rarity == 2" style="color: #844693">{{ v.name }}<span v-if="v.other.strengthening > 0">+{{ v.other.strengthening }}</span></span>
                                            <span class="itme-title" v-else-if="v.other.rarity == 3" style="color: rgb(225 0 255)">{{ v.name }}<span v-if="v.other.strengthening > 0">+{{ v.other.strengthening }}</span></span>
                                            <span class="itme-title" v-else-if="v.other.rarity == 4" style="color: #dde4b6">{{ v.name }}<span v-if="v.other.strengthening > 0">+{{ v.other.strengthening }}</span></span>
                                            <span class="itme-title" v-else-if="v.other.rarity == 5" style="color: #a7ad7a">{{ v.name }}<span v-if="v.other.strengthening > 0">+{{ v.other.strengthening }}</span></span>
                                            <span class="itme-title" v-else-if="v.other.rarity == 6" style="color: #d04d02">{{ v.name }}<span v-if="v.other.strengthening > 0">+{{ v.other.strengthening }}</span></span>
                                            <span class="itme-title" v-else-if="v.other.rarity == 7" style="color: #e6ff02">{{ v.name }}<span v-if="v.other.strengthening > 0">+{{ v.other.strengthening }}</span></span>
                                          </div>
                                          <div>
                                            <el-row :gutter="12">
                                              <el-col :span="17">
                                                <span style="float: right;">￥{{ v.coin }}</span>
                                              </el-col>
                                              <el-col :span="7" style="padding-left:0px;padding-right:0px;">
                                                <div style="text-align: center;"><span class="bin-an" @click="BuyItmes(category2,v.id)">购买</span></div>
                                              </el-col>
                                            </el-row>
                                          </div>
                                        </el-col>
                                        
                                      </el-row>
                                    </div>
                                 </el-col>  
                              </div>
                              <div v-else style="margin-top:118px;text-align:center;">
                                 <span>无数据</span>
                              </div>
                                
                            </el-row>
                            <!-- 消耗品 -->
                            <el-row :gutter="12" v-show="category2 ==2">
                              <div v-if=" info.items.consumables.length > 0">
                                 <el-col :span="12" v-for="(v, i) in info.items.consumables" :key="i">
                                     <div style="background: #3e3e3e;height: 42px;padding: 4px 0px;margin-top:6px;cursor:pointer;">
                                      <el-row :gutter="12">
                                        <el-col :span="5">
                                          <el-tooltip class="item" effect="dark" content="" placement="top">
                                            <div slot="content" style="width:198px;">
                                                <div>
                                                  <img v-if="v.ico_data != ''" :src="v.ico_data" style="width:28px;height:28px;">
                                                  <img v-else src="../../../public/static/plug/tian/static/images/6.png" style="width:28px;height:28px;">
                                                  <span  v-if="v.other.rarity == 0" style="margin-left:12px;color: #fff;">{{ v.name }}</span>
                                                  <span  v-else-if="v.other.rarity == 1" style="margin-left:12px;color: rgb(244 159 255)">{{ v.name }}</span>
                                                  <span v-else-if="v.other.rarity == 2" style="margin-left:12px;color: #844693">{{ v.name }}</span>
                                                  <span v-else-if="v.other.rarity == 3" style="margin-left:12px;color: rgb(225 0 255)">{{ v.name }}</span>
                                                  <span  v-else-if="v.other.rarity == 4" style="margin-left:12px;color: #dde4b6">{{ v.name }}</span>
                                                  <span  v-else-if="v.other.rarity == 5" style="margin-left:12px;color: #a7ad7a">{{ v.name }}</span>
                                                  <span  v-else-if="v.other.rarity == 6" style="margin-left:12px;color: #d04d02">{{ v.name }}</span>
                                                  <span  v-else-if="v.other.rarity == 7" style="margin-left:12px;color: #e6ff02">{{ v.name }}</span>
                                      
                                                </div>
                                                <div>
                                                  <p style="text-align: right;margin-top:20px;color:#9195ff;">
                                                    <span  v-if="v.other.rarity == 0" style="color: #fff;">普通</span>
                                                    <span  v-else-if="v.other.rarity == 1" style="color: rgb(244 159 255);">高级</span>
                                                    <span  v-else-if="v.other.rarity == 2" style="color: #844693;">稀有</span>
                                                    <span  v-else-if="v.other.rarity == 3" style="color: rgb(225 0 255);">神器</span>
                                                    <span  v-else-if="v.other.rarity == 4" style="color: #dde4b6;">史诗</span>
                                                    <span  v-else-if="v.other.rarity == 5" style="color: #a7ad7a;">勇者</span>
                                                    <span  v-else-if="v.other.rarity == 6" style="color: #d04d02;">传说</span>
                                                    <span  v-else-if="v.other.rarity == 7" style="color: #e6ff02;">神话</span>
                                                  </p>
                                                </div>
                                                <div>
                                                  <p>
                                                    <span v-if="v.type == 2">消耗品</span>
                                                    <span v-if="v.type == 3">材料</span>
                                                  </p>
                                                </div>
                                                <div>
                                                  <p style="text-align: center;margin-top:20px;"><span style="color:rgb(112 110 116);">物品详情存在一定的数据差异，实际数据去自行到游戏中对比，物品购买后直接发送至您的邮箱中，请注意查收。</span></p>
                                                </div>
                                            </div>
                                            <div>
                                            <img v-if="v.ico_data != ''" :src="v.ico_data" style="width:28px;height:28px;">
                                            <img v-else src="../../../public/static/plug/tian/static/images/6.png" style="width:28px;height:28px;">
                                              <div style="position: relative;top:-30px;text-align: right;">
                                                 <span>{{v.other.num}}</span>
                                              </div>
                                            </div>
                                          </el-tooltip> 
                                        </el-col>
                                        <el-col :span="19" style="padding-left:0px;">
                                          <div style="display: inline-block;">
                                            <span class="itme-title" v-if="v.other.rarity == 0" style="color: #fff;">{{ v.name }}</span>
                                            <span class="itme-title" v-else-if="v.other.rarity == 1" style="color: rgb(244 159 255)">{{ v.name }}</span>
                                            <span class="itme-title" v-else-if="v.other.rarity == 2" style="color: #844693">{{ v.name }}</span>
                                            <span class="itme-title" v-else-if="v.other.rarity == 3" style="color: rgb(225 0 255)">{{ v.name }}</span>
                                            <span class="itme-title" v-else-if="v.other.rarity == 4" style="color: #dde4b6">{{ v.name }}</span>
                                            <span class="itme-title" v-else-if="v.other.rarity == 5" style="color: #a7ad7a">{{ v.name }}</span>
                                            <span class="itme-title" v-else-if="v.other.rarity == 6" style="color: #d04d02">{{ v.name }}</span>
                                            <span class="itme-title" v-else-if="v.other.rarity == 7" style="color: #e6ff02">{{ v.name }}</span>
                                          </div>
                                          <div>
                                            <el-row :gutter="12">
                                              <el-col :span="17">
                                                <span style="float: right;">￥{{ v.coin }}</span>
                                              </el-col>
                                              <el-col :span="7" style="padding-left:0px;padding-right:0px;">
                                                <div style="text-align: center;"><span class="bin-an" @click="BuyItmes(category2,v.id)">购买</span></div>
                                              </el-col>
                                            </el-row>
                                          </div>
                                        </el-col>
                                        
                                      </el-row>
                                    </div>
                                 </el-col> 
                              </div>
                              <div v-else style="margin-top:118px;text-align:center;">
                                 <span>无数据</span>
                              </div> 
                            </el-row>
                            <!-- 材料 -->
                            <el-row :gutter="12" v-show="category2 == 3">
                              <div v-if=" info.items.material.length > 0">
                                 <el-col :span="12" v-for="(v, i) in info.items.material" :key="i">
                                     <div style="background: #3e3e3e;height: 42px;padding: 4px 0px;margin-top:6px;cursor:pointer;">
                                      <el-row :gutter="12">
                                        <el-col :span="5">
                                          <el-tooltip class="item" effect="dark" content="" placement="top">
                                            <div slot="content" style="width:198px;">
                                                <div>
                                                  <img v-if="v.ico_data != ''" :src="v.ico_data" style="width:28px;height:28px;">
                                                  <img v-else src="../../../public/static/plug/tian/static/images/6.png" style="width:28px;height:28px;">
                                                  <span  v-if="v.other.rarity == 0" style="margin-left:12px;color: #fff;">{{ v.name }}</span>
                                                  <span  v-else-if="v.other.rarity == 1" style="margin-left:12px;color: rgb(244 159 255)">{{ v.name }}</span>
                                                  <span v-else-if="v.other.rarity == 2" style="margin-left:12px;color: #844693">{{ v.name }}</span>
                                                  <span v-else-if="v.other.rarity == 3" style="margin-left:12px;color: rgb(225 0 255)">{{ v.name }}</span>
                                                  <span  v-else-if="v.other.rarity == 4" style="margin-left:12px;color: #dde4b6">{{ v.name }}</span>
                                                  <span  v-else-if="v.other.rarity == 5" style="margin-left:12px;color: #a7ad7a">{{ v.name }}</span>
                                                  <span  v-else-if="v.other.rarity == 6" style="margin-left:12px;color: #d04d02">{{ v.name }}</span>
                                                  <span  v-else-if="v.other.rarity == 7" style="margin-left:12px;color: #e6ff02">{{ v.name }}</span>
                                                </div>
                                                <div>
                                                  <p style="text-align: right;margin-top:20px;color:#9195ff;">
                                                    <span  v-if="v.other.rarity == 0" style="color: #fff;">普通</span>
                                                    <span  v-else-if="v.other.rarity == 1" style="color: rgb(244 159 255);">高级</span>
                                                    <span  v-else-if="v.other.rarity == 2" style="color: #844693;">稀有</span>
                                                    <span  v-else-if="v.other.rarity == 3" style="color: rgb(225 0 255);">神器</span>
                                                    <span  v-else-if="v.other.rarity == 4" style="color: #dde4b6;">史诗</span>
                                                    <span  v-else-if="v.other.rarity == 5" style="color: #a7ad7a;">勇者</span>
                                                    <span  v-else-if="v.other.rarity == 6" style="color: #d04d02;">传说</span>
                                                    <span  v-else-if="v.other.rarity == 7" style="color: #e6ff02;">神话</span>
                                                  </p>
                                                </div>
                                                <div>
                                                  <p>
                                                    <span v-if="v.type == 2">消耗品</span>
                                                    <span v-if="v.type == 3">材料</span>
                                                  </p>
                                                </div>
                                                <div>
                                                  <p style="text-align: center;margin-top:20px;"><span style="color:rgb(112 110 116);">物品详情存在一定的数据差异，实际数据去自行到游戏中对比，物品购买后直接发送至您的邮箱中，请注意查收。</span></p>
                                                </div>
                                            </div>
                                            <div>
                                              <img v-if="v.ico_data != ''" :src="v.ico_data" style="width:28px;height:28px;">
                                              <img v-else src="../../../public/static/plug/tian/static/images/6.png" style="width:28px;height:28px;">
                                              <div style="position: relative;top:-30px;text-align: right;">
                                                 <span>{{v.other.num}}</span>
                                              </div>
                                            </div>
                                          </el-tooltip> 
                                        </el-col>
                                        <el-col :span="19" style="padding-left:0px;">
                                          <div style="display: inline-block;">
                                            <span class="itme-title" v-if="v.other.rarity == 0" style="color: #fff;">{{ v.name }}</span>
                                            <span class="itme-title" v-else-if="v.other.rarity == 1" style="color: rgb(244 159 255)">{{ v.name }}</span>
                                            <span class="itme-title" v-else-if="v.other.rarity == 2" style="color: #844693">{{ v.name }}</span>
                                            <span class="itme-title" v-else-if="v.other.rarity == 3" style="color: rgb(225 0 255)">{{ v.name }}</span>
                                            <span class="itme-title" v-else-if="v.other.rarity == 4" style="color: #dde4b6">{{ v.name }}</span>
                                            <span class="itme-title" v-else-if="v.other.rarity == 5" style="color: #a7ad7a">{{ v.name }}</span>
                                            <span class="itme-title" v-else-if="v.other.rarity == 6" style="color: #d04d02">{{ v.name }}</span>
                                            <span class="itme-title" v-else-if="v.other.rarity == 7" style="color: #e6ff02">{{ v.name }}</span>
                                          </div>
                                          <div>
                                            <el-row :gutter="12">
                                              <el-col :span="17">
                                                <span style="float: right;">￥{{ v.coin }}</span>
                                              </el-col>
                                              <el-col :span="7" style="padding-left:0px;padding-right:0px;">
                                                <div style="text-align: center;"><span class="bin-an" @click="BuyItmes(category2,v.id)">购买</span></div>
                                              </el-col>
                                            </el-row>
                                          </div>
                                        </el-col>  
                                      </el-row>
                                    </div>
                                 </el-col>  
                              </div>
                              <div v-else style="margin-top:118px;text-align:center;">
                                 <span>无数据</span>
                              </div> 
                            </el-row>
                            <!-- CDK -->
                            <el-row :gutter="12" v-show="category2 == 4">
                               <div v-if=" info.items.cdk.length > 0">
                                 <el-col :span="12" v-for="(vcc, i) in info.items.cdk" :key="i">
                                     <div style="background: #3e3e3e;height: 42px;padding: 4px 0px;margin-top:6px;cursor:pointer;">
                                      <el-row :gutter="12">
                                        <el-col :span="5">
                                          <el-tooltip class="item" effect="dark" content="" placement="top">
                                            <div slot="content" style="width:198px;">
                                                <div>
                                                  <img src="../../../public/static/plug/tian/static/images/6.png" style="width:28px;height:28px;"/>
                                                  <span style="margin-left:12px;color:rgb(172 182 129);">{{ vcc.cdkname }}</span>
                                                </div>
                                                <div>
                                                  <p style="text-align: right;margin-top:20px;color:#9195ff;"><span>卡密</span></p>
                                                </div>
                                                <div  style="text-align: left;margin-top:10px;">
                                                  <p style="margin-bottom:6px;color:#a18989;" v-for="va, indx in vcc.code_data"  :key="indx">{{ va.name }}*{{ va.num }}</p>
                                                </div>
                                                <div>
                                                  <p style="text-align: center;margin-top:20px;"><span style="color:rgb(112 110 116);">购买后商品请在交易记录中查询卡密，拿到卡密请尽快使用，避免商家二次出售或使用等导致失效问题。</span></p>
                                                </div>
                                            </div>
                                            <img src="../../../public/static/plug/tian/static/images/6.png"
                                          style="idth:28px;height:28px;">
                                          </el-tooltip>
                                        </el-col>
                                        <el-col :span="19" style="padding-left:0px;">
                                          <div style="display: inline-block;"><span class="itme-title">{{ vcc.cdkname }}</span></div>
                                          <div>
                                            <el-row :gutter="12">
                                              <el-col :span="17">
                                                <span style="float: right;">￥{{ vcc.yd_coin }}</span>
                                              </el-col>
                                              <el-col :span="7" style="padding-left:0px;padding-right:0px;">
                                                <div style="text-align: center;"><span class="bin-an" @click="BuyItmes(category2,vcc.cdk)">购买</span></div>
                                              </el-col>
                                            </el-row>
                                          </div>
                                        </el-col>
                                      </el-row>
                                    </div>
                                 </el-col>  
                               </div>
                                <div v-else style="margin-top:118px;text-align:center;">
                                 <span>无数据</span>
                              </div> 
                            </el-row>
                            <!-- 商城 -->
                            <el-row :gutter="12" v-show="category2 == 5">
                               <div v-if=" info.items.shop.length > 0">
                                 <el-col :span="12" v-for="(vcc, i) in info.items.shop" :key="i">
                                     <div style="background: #3e3e3e;height: 42px;padding: 4px 0px;margin-top:6px;cursor:pointer;">
                                      <el-row :gutter="12">
                                        <el-col :span="5">
                                          <el-tooltip class="item" effect="dark" content="" placement="top">
                                            <div slot="content" style="width:198px;">
                                                <div>
                                                  <img :src="vcc.ioc" style="width:28px;height:28px;"/>
                                                  <span style="margin-left:12px;color:#d1ff0e;">{{ vcc.name }}</span>
                                                </div>
                                                <div>
                                                  <p style="text-align: right;margin-top:20px;color:#9195ff;"><span>礼包</span></p>
                                                </div>
                                                <div>
                                                  <p style="text-align: left;margin-top:10px;"><span style="color:#a179e2;">礼包内包含了以下内容</span></p>
                                                </div>
                                                <div  style="text-align: left;margin-top:10px;">
                                                  <p style="margin-bottom:6px;color:#a18989;" v-for="va, indx in vcc.code_data"  :key="indx">{{ va.name }}*{{ va.num }}</p>
                                                </div>
                                                <div>
                                                  <p style="text-align: center;margin-top:20px;"><span style="color:rgb(112 110 116);">购买后商品直接发送至当前角色邮箱中，请注意查收。</span></p>
                                                </div>
                                            </div>
                                            <img :src="vcc.ioc" style="width: 28px;height:28px;">
                                          </el-tooltip>
                                        </el-col>
                                        <el-col :span="19" style="padding-left:0px;">
                                          <div style="display: inline-block;"><span class="itme-title" style="color:#d1ff0e;">{{ vcc.name }}</span></div>
                                          <div>
                                            <el-row :gutter="12">
                                              <el-col :span="17">
                                                <span style="float: right;">￥{{ vcc.cera }}</span>
                                              </el-col>
                                              <el-col :span="7" style="padding-left:0px;padding-right:0px;">
                                                <div style="text-align: center;"><span class="bin-an" @click="BuyItmes(category2,vcc.id)">购买</span></div>
                                              </el-col>
                                            </el-row>
                                          </div>
                                        </el-col>
                                      </el-row>
                                    </div>
                                 </el-col> 
                               </div>
                              <div v-else style="margin-top:118px;text-align:center;">
                                 <span>无数据</span>
                              </div> 
                            </el-row>
                          </div>
                        </el-col>
                    </el-row>
                </div>
                
            </div>
            <div class="page page-2" :class="{'active':category1 == 2}">
              <div style="margin-top:68px;padding:0px 6px;">
                    <el-row :gutter="12">
                        <!-- 这边 -->
                        <el-col :span="8">
                          <el-row :gutter="12">
                            <el-col :span="24" style="padding-left:0px;">
                                <div style="margin-top:64px;">
                                  <div style="text-align:center;font-weight: 700;"><span>我的<span v-if="info.config.source == 1">集币</span><span v-if="info.config.source == 2">点券</span><span v-if="info.config.source == 3">代币券</span></span></div>
                                  <div style="margin-top:28px;text-align:center;"><span style="font-size:20px;color: #f00;font-weight: 700;">{{info.user.money}}</span></div>
                                </div>
                              </el-col>
                              <el-col :span="24" style="padding-left:0px;">
                                <div style="margin-top:28px;margin-bottom:6px;">
                                  <div style="text-align:center;font-weight: 700;"><span>实时交易快播</span></div>
                                </div>
                                <div>
                                  <div style="height: 250px;overflow: auto;">
                                    <div v-if=" info.items.buylog.length > 0">
                                      <el-row :gutter="12" style="margin-top:6px;" v-for="(v, i) in info.items.buylog" :key="i">
                                        <el-col :span="5">
                                          <el-tooltip class="item" effect="dark" content=""  placement="right-start">
                                              <div slot="content" style="width:198px;">
                                                <div v-if="v.type == 1">
                                                    <div>
                                                      <img v-if="v.ico_data != ''" :src="v.ico_data" style="width:28px;height:28px;">
                                                      <img v-else src="../../../public/static/plug/tian/static/images/6.png" style="width:28px;height:28px;">
                                                      <span  v-if="v.other.rarity == 0" style="margin-left:12px;color: #fff;">{{ v.name }}<span v-if="v.other.strengthening > 0">+{{ v.other.strengthening }}</span></span>
                                                      <span  v-else-if="v.other.rarity == 1" style="margin-left:12px;color: rgb(244 159 255)">{{ v.name }}<span v-if="v.other.strengthening > 0">+{{ v.other.strengthening }}</span></span>
                                                      <span v-else-if="v.other.rarity == 2" style="margin-left:12px;color: #844693">{{ v.name }}<span v-if="v.other.strengthening > 0">+{{ v.other.strengthening }}</span></span>
                                                      <span v-else-if="v.other.rarity == 3" style="margin-left:12px;color: rgb(225 0 255)">{{ v.name }}<span v-if="v.other.strengthening > 0">+{{ v.other.strengthening }}</span></span>
                                                      <span  v-else-if="v.other.rarity == 4" style="margin-left:12px;color: #dde4b6">{{ v.name }}<span v-if="v.other.strengthening > 0">+{{ v.other.strengthening }}</span></span>
                                                      <span  v-else-if="v.other.rarity == 5" style="margin-left:12px;color: #a7ad7a">{{ v.name }}<span v-if="v.other.strengthening > 0">+{{ v.other.strengthening }}</span></span>
                                                      <span  v-else-if="v.other.rarity == 6" style="margin-left:12px;color: #d04d02">{{ v.name }}<span v-if="v.other.strengthening > 0">+{{ v.other.strengthening }}</span></span>
                                                      <span  v-else-if="v.other.rarity == 7" style="margin-left:12px;color: #e6ff02">{{ v.name }}<span v-if="v.other.strengthening > 0">+{{ v.other.strengthening }}</span></span>
                                          
                                                    </div>
                                                    <div>
                                                      <p style="text-align: right;margin-top:20px;color:#9195ff;">
                                                        <span  v-if="v.other.rarity == 0" style="color: #fff;">普通</span>
                                                        <span  v-else-if="v.other.rarity == 1" style="color: rgb(244 159 255);">高级</span>
                                                        <span  v-else-if="v.other.rarity == 2" style="color: #844693;">稀有</span>
                                                        <span  v-else-if="v.other.rarity == 3" style="color: rgb(225 0 255);">神器</span>
                                                        <span  v-else-if="v.other.rarity == 4" style="color: #dde4b6;">史诗</span>
                                                        <span  v-else-if="v.other.rarity == 5" style="color: #a7ad7a;">勇者</span>
                                                        <span  v-else-if="v.other.rarity == 6" style="color: #d04d02;">传说</span>
                                                        <span  v-else-if="v.other.rarity == 7" style="color: #e6ff02;">神话</span>
                                                      </p>
                                                    </div>
                                                    <div>
                                                    <p style="text-align: right;">
                                                      <span  style="color: #fff;" v-if="v.other.items_type == 10">武器</span>
                                                      <span  style="color: #fff;" v-if="v.other.items_type == 11">称号</span>
                                                      <span  style="color: #fff;" v-if="v.other.items_type == 12">上衣</span>
                                                      <span  style="color: #fff;" v-if="v.other.items_type == 13">头肩</span>
                                                      <span  style="color: #fff;" v-if="v.other.items_type == 14">下衣</span>
                                                      <span  style="color: #fff;" v-if="v.other.items_type == 15">鞋子</span>
                                                      <span  style="color: #fff;" v-if="v.other.items_type == 16">腰带</span>
                                                      <span  style="color: #fff;" v-if="v.other.items_type == 17">项链</span>
                                                      <span  style="color: #fff;" v-if="v.other.items_type == 18">手镯</span>
                                                      <span  style="color: #fff;" v-if="v.other.items_type == 19">戒指</span>
                                                      <span  style="color: #fff;" v-if="v.other.items_type == 20">辅助装备</span>
                                                      <span  style="color: #fff;" v-if="v.other.items_type == 21">魔法石</span>
                                                    </p>
                                                  </div>
                                                  <div v-if="inArray(v.other.rarity,[2,3,4,5,6,7,8,9])">
                                                    <p style="text-align: right;margin-top: 20px;">
                                                      <span  style="color: #fff;" v-if="v.other.seal_flag == 1" >{{SealFlag(v.other.strengthening)}}</span>
                                                      <span  style="color: rgb(206 9 9);" v-else-if="v.other.seal_flag == 0">无法交易</span>
                                                    </p>
                                                  </div>
                                                  <div>
                                                    <p>
                                                      <span  v-if="v.other.maxlevel > 0" style="color: #fff;">等级{{v.other.maxlevel}}以上使用</span>
                                                      <span  v-else  style="color: #fff;">等级不限制</span>
                                                      <span  v-if="v.other.num == 1" style="color: #fff;float: right;">最下级</span>
                                                      <span  v-if="v.other.num == 2 || v.other.num == 696817050" style="color: #fff;float: right;">上级</span>
                                                      <span  v-if="v.other.num == 3 || v.other.num == 1553487634" style="color: #fff;float: right;">下级</span>
                                                      <span  v-if="v.other.num == 4 || v.other.num == 1294422825" style="color: #fff;float: right;">最上级</span>
                                                    </p>
                                                  </div>
                                                  <div v-if="v.other.growth > 0">
                                                    <p style="text-align: left;margin-top: 20px;">
                                                      <span v-if="v.other.growth == 1"  style="color: rgb(239 24 255);">增幅：异次元体力 + {{v.other.growth_fj}}</span>
                                                      <span v-if="v.other.growth == 2"  style="color: rgb(239 24 255);">增幅：异次元精神 + {{v.other.growth_fj}}</span>
                                                      <span v-if="v.other.growth == 3"  style="color: rgb(239 24 255);">增幅：异次元力量 + {{v.other.growth_fj}}</span>
                                                      <span v-if="v.other.growth == 4"  style="color: rgb(239 24 255);">增幅：异次元智力 + {{v.other.growth_fj}}</span>
                                                    </p>
                                                  </div>
                                                  <div v-if="v.other.forging >0">
                                                    <p style="text-align: left;margin-top: 10px;">
                                                      <span  style="color: rgb(246 153 253);">锻造第{{v.other.forging}}阶段：独立攻击 +{{6.2*v.other.forging}}</span>
                                                    </p>
                                                  </div>
                                                  <div v-if="v.other.extend_info > 0">
                                                    <p style="text-align: left;margin-top: 20px;">
                                                      <span   style="color:rgb(190 212 8);">附魔宝珠 : {{v.other.extend_name}}</span>
                                                    </p>
                                                  </div>
                                                    <div>
                                                      <p style="text-align: center;margin-top:20px;"><span style="color:rgb(112 110 116);">物品详情存在一定的数据差异，实际数据去自行到游戏中对比，物品购买后直接发送至您的邮箱中，请注意查收。</span></p>
                                                    </div>
                                                  </div>
                                                  <div v-if="v.type == 2">
                                                    <div>
                                                      <img v-if="v.ico_data != ''" :src="v.ico_data" style="width:28px;height:28px;">
                                                      <img v-else src="../../../public/static/plug/tian/static/images/6.png" style="width:28px;height:28px;">
                                                      <span  v-if="v.other.rarity == 0" style="margin-left:12px;color: #fff;">{{ v.name }}</span>
                                                      <span  v-else-if="v.other.rarity == 1" style="margin-left:12px;color: rgb(244 159 255)">{{ v.name }}</span>
                                                      <span v-else-if="v.other.rarity == 2" style="margin-left:12px;color: #844693">{{ v.name }}</span>
                                                      <span v-else-if="v.other.rarity == 3" style="margin-left:12px;color: rgb(225 0 255)">{{ v.name }}</span>
                                                      <span  v-else-if="v.other.rarity == 4" style="margin-left:12px;color: #dde4b6">{{ v.name }}</span>
                                                      <span  v-else-if="v.other.rarity == 5" style="margin-left:12px;color: #a7ad7a">{{ v.name }}</span>
                                                      <span  v-else-if="v.other.rarity == 6" style="margin-left:12px;color: #d04d02">{{ v.name }}</span>
                                                      <span  v-else-if="v.other.rarity == 7" style="margin-left:12px;color: #e6ff02">{{ v.name }}</span>
                                          
                                                    </div>
                                                    <div>
                                                      <p style="text-align: right;margin-top:20px;color:#9195ff;">
                                                        <span  v-if="v.other.rarity == 0" style="color: #fff;">普通</span>
                                                        <span  v-else-if="v.other.rarity == 1" style="color: rgb(244 159 255);">高级</span>
                                                        <span  v-else-if="v.other.rarity == 2" style="color: #844693;">稀有</span>
                                                        <span  v-else-if="v.other.rarity == 3" style="color: rgb(225 0 255);">神器</span>
                                                        <span  v-else-if="v.other.rarity == 4" style="color: #dde4b6;">史诗</span>
                                                        <span  v-else-if="v.other.rarity == 5" style="color: #a7ad7a;">勇者</span>
                                                        <span  v-else-if="v.other.rarity == 6" style="color: #d04d02;">传说</span>
                                                        <span  v-else-if="v.other.rarity == 7" style="color: #e6ff02;">神话</span>
                                                      </p>
                                                    </div>
                                                    <div>
                                                      <p>
                                                        <span v-if="v.type == 2">消耗品</span>
                                                        <span v-if="v.type == 3">材料</span>
                                                      </p>
                                                    </div>
                                                  
                                                  </div>
                                                  <div v-if="v.type == 3">
                                                    <div>
                                                      <img v-if="v.ico_data != ''" :src="v.ico_data" style="width:28px;height:28px;">
                                                      <img v-else src="../../../public/static/plug/tian/static/images/6.png" style="width:28px;height:28px;">
                                                      <span  v-if="v.other.rarity == 0" style="margin-left:12px;color: #fff;">{{ v.name }}</span>
                                                      <span  v-else-if="v.other.rarity == 1" style="margin-left:12px;color: rgb(244 159 255)">{{ v.name }}</span>
                                                      <span v-else-if="v.other.rarity == 2" style="margin-left:12px;color: #844693">{{ v.name }}</span>
                                                      <span v-else-if="v.other.rarity == 3" style="margin-left:12px;color: rgb(225 0 255)">{{ v.name }}</span>
                                                      <span  v-else-if="v.other.rarity == 4" style="margin-left:12px;color: #dde4b6">{{ v.name }}</span>
                                                      <span  v-else-if="v.other.rarity == 5" style="margin-left:12px;color: #a7ad7a">{{ v.name }}</span>
                                                      <span  v-else-if="v.other.rarity == 6" style="margin-left:12px;color: #d04d02">{{ v.name }}</span>
                                                      <span  v-else-if="v.other.rarity == 7" style="margin-left:12px;color: #e6ff02">{{ v.name }}</span>
                                          
                                                    </div>
                                                    <div>
                                                      <p style="text-align: right;margin-top:20px;color:#9195ff;">
                                                        <span  v-if="v.other.rarity == 0" style="color: #fff;">普通</span>
                                                        <span  v-else-if="v.other.rarity == 1" style="color: rgb(244 159 255);">高级</span>
                                                        <span  v-else-if="v.other.rarity == 2" style="color: #844693;">稀有</span>
                                                        <span  v-else-if="v.other.rarity == 3" style="color: rgb(225 0 255);">神器</span>
                                                        <span  v-else-if="v.other.rarity == 4" style="color: #dde4b6;">史诗</span>
                                                        <span  v-else-if="v.other.rarity == 5" style="color: #a7ad7a;">勇者</span>
                                                        <span  v-else-if="v.other.rarity == 6" style="color: #d04d02;">传说</span>
                                                        <span  v-else-if="v.other.rarity == 7" style="color: #e6ff02;">神话</span>
                                                      </p>
                                                    </div>
                                                    <div>
                                                      <p>
                                                        <span v-if="v.type == 2">消耗品</span>
                                                        <span v-if="v.type == 3">材料</span>
                                                      </p>
                                                    </div>
                                                  
                                                  </div>
                                                  <div v-if="v.type == 4">
                                                    <div>
                                                      <img src="../../../public/static/plug/tian/static/images/6.png" style="width:28px;height:28px;"/>
                                                      <span style="margin-left:12px;color:rgb(172 182 129);">{{ v.name }}</span>
                                                    </div>
                                                  </div>
                                                  <div v-if="v.type == 5">
                                                    <div>
                                                        <img :src="v.ioc" style="width:28px;height:28px;"/>
                                                        <span style="margin-left:12px;color:#d1ff0e;">{{ v.name }}</span>
                                                      </div>
                                                      <div>
                                                        <p style="text-align: right;margin-top:20px;color:#9195ff;"><span>礼包</span></p>
                                                      </div>
                                                      <div>
                                                        <p style="text-align: left;margin-top:10px;"><span style="color:#a179e2;">礼包内包含了以下内容</span></p>
                                                      </div>
                                                      <div  style="text-align: left;margin-top:10px;">
                                                        <p style="margin-bottom:6px;color:#a18989;" v-for="va, indx in v.code_data"  :key="indx">{{ va.name }}*{{ va.num }}</p>
                                                      </div>
                                                  </div>
                                              </div>
                                              <img v-if="v.type == 1 && v.ico_data != ''" :src="v.ico_data" style="width:28px;height:28px;">
                                              <img v-else-if="v.type == 2 && v.ico_data != ''" :src="v.ico_data" style="width:28px;height:28px;">
                                              <img v-else-if="v.type == 3 && v.ico_data != ''" :src="v.ico_data" style="width:28px;height:28px;">
                                              <img v-else-if="v.type == 4" src="../../../public/static/plug/tian/static/images/6.png" style="width:28px;height:28px;">
                                              <img v-else-if="v.type == 5" :src="v.ioc" style="width:28px;height:28px;">
                                              <img v-else src="../../../public/static/plug/tian/static/images/6.png" style="width:28px;height:28px;">
                                          </el-tooltip> 
                                        </el-col>
                                        <el-col :span="19" style="padding-left:0px;padding-right:0px;">
                                          <span class="task-title" style="margin-left:6px;position: relative;top: 6px;left: -10px;"><span v-if="v.type == 1" style="color:#f70000; ">(装备)</span><span v-else-if="v.type == 2" style="color:#f70000;">(消耗)</span><span v-else-if="v.type == 3" style="color:#f70000;">(材料)</span><span v-else-if="v.type == 4" style="color:#f70000;">(卡密)</span><span v-else-if="v.type == 5" style="color:#f70000;">(商城)</span>
                                              <span  style="color: rgb(86 32 32);">{{ v.name }}
                                                <span v-if="v.type == 1 && v.other.strengthening > 0">+{{ v.other.strengthening }}</span>
                                                <span v-else-if="v.type == 2 || v.type == 3">x{{ v.other.num }}</span>
                                              </span>
                                          </span>
                                        </el-col>
                                      </el-row>
                                      </div>
                                     <div v-else style="margin-top:118px;text-align:center;">
                                      <span>无数据</span>
                                    </div> 
                                  </div>
                                </div>
                              </el-col>
                          </el-row>
                        </el-col>
                        <!-- 右边 -->
                        <el-col :span="16">
                          <div class="tab tab-mid" style="display: inline-block;">
                            <div class="tab-item midtab" @click="category3 = 1" :class="{'active':category3 == 1}" v-if="inArray(1,info.config.open) || inArray(2,info.config.open) || inArray(3,info.config.open) || inArray(4,info.config.open)">我的出售</div>
                            <div class="tab-item midtab" @click="category3 = 2" :class="{'active':category3 == 2}">交易记录</div>
                            <div class="tab-item midtab" @click="category3 = 4" :class="{'active':category3 == 4}" v-if="info.config.withdrawal == 1">提现记录</div>
                            <div class="tab-item midtab" @click="category3 = 5" :class="{'active':category3 == 5}" v-if="info.config.source == 1">集币充值</div>
                          </div>
                          <div style="margin-left:-7px;height:392px;">

                            <!-- 我的出售 -->
                            <el-row :gutter="12" v-if="category3 == 1">
                                <el-col :span="24"> 
                                    <div style="margin-top: 30px;margin-left: 10px;">
                                       <ul>
                                          <li style="margin-bottom:10px;">
                                            <span>上架类型：</span>
                                            <select v-model="form_add.type" style="width:136px;" @change="ChooseType">
                                              <option :value="0">请选择</option>
                                              <option :value="1" v-if="inArray(1,info.config.open)">装备</option>
                                              <option :value="2" v-if="inArray(2,info.config.open)">消耗品</option>
                                              <option :value="3" v-if="inArray(3,info.config.open)">材料</option>
                                              <option :value="4" v-if="inArray(4,info.config.open)">CDK</option>
                                            </select>
                                          </li>
                                          <li v-if="form_add.type == 1 || form_add.type == 2 || form_add.type == 3" style="margin-bottom:10px;">
                                            <span style="color:#e41111;margin-left:60px">请将要上架物品放至游戏快捷栏第一个格子上,然后点[上架]</span>
                                          </li>
                                          <li v-if="form_add.type == 4" style="margin-bottom:10px;margin-left:5px;">
                                            <span>出售CDK：</span>
                                            <input type="text" v-model="form_add.cdk" style="border: 1px solid #767676;padding: 0px 4px;"/>
                                          </li>
                                          <li style="margin-bottom:10px;">
                                            <span><span v-if="info.config.source == 1">集币</span><span v-if="info.config.source == 2">点券</span><span v-if="info.config.source == 3">代币</span>价格：</span>
                                            <input type="text" v-model="form_add.coin" style="border: 1px solid #767676;padding: 0px 4px;"/>
                                          </li>
                                       </ul>
                                    </div>
                                    <div style="text-align: center;margin-top: 16px;">
                                      <button class="shelves" @click="AddShop">上 架</button>
                                    </div>
                                </el-col>  
                                <el-col :span="24" style="padding-left:0px;padding-right:0px;"> 
                                    <div style="margin-top: 10px;">
                                      <div class="tr">
                                        <span class="th-a">商品名称</span><span v-if="info.config.source == 1">集币</span><span v-if="info.config.source == 2">点券</span><span v-if="info.config.source == 3">代币券</span><span style="width:117px;">操作</span>
                                      </div>
                                      <table style="background:#232323;"  :class="{'tsys1':form_add.type == 0,'tsys2':form_add.type != 0}">
                                        <div v-if="info.user.intheshelf.length > 0">
                                          <tr class="tt" v-for="(v, i) in info.user.intheshelf" :key="i">
                                            <td class="td-a">
                                              <el-tooltip class="item" effect="dark" content="" placement="top">
                                                <div slot="content" style="width:198px;">
                                                  <div v-if="v.type == 1">
                                                      <div>
                                                        <img v-if="v.ico_data != ''" :src="v.ico_data" style="width:28px;height:28px;">
                                                        <img v-else src="../../../public/static/plug/tian/static/images/6.png" style="width:28px;height:28px;">
                                                        <span  v-if="v.other.rarity == 0" style="margin-left:12px;color: #fff;">{{ v.name }}<span v-if="v.other.strengthening > 0">+{{ v.other.strengthening }}</span></span>
                                                        <span  v-else-if="v.other.rarity == 1" style="margin-left:12px;color: rgb(244 159 255)">{{ v.name }}<span v-if="v.other.strengthening > 0">+{{ v.other.strengthening }}</span></span>
                                                        <span v-else-if="v.other.rarity == 2" style="margin-left:12px;color: #844693">{{ v.name }}<span v-if="v.other.strengthening > 0">+{{ v.other.strengthening }}</span></span>
                                                        <span v-else-if="v.other.rarity == 3" style="margin-left:12px;color: rgb(225 0 255)">{{ v.name }}<span v-if="v.other.strengthening > 0">+{{ v.other.strengthening }}</span></span>
                                                        <span  v-else-if="v.other.rarity == 4" style="margin-left:12px;color: #dde4b6">{{ v.name }}<span v-if="v.other.strengthening > 0">+{{ v.other.strengthening }}</span></span>
                                                        <span  v-else-if="v.other.rarity == 5" style="margin-left:12px;color: #a7ad7a">{{ v.name }}<span v-if="v.other.strengthening > 0">+{{ v.other.strengthening }}</span></span>
                                                        <span  v-else-if="v.other.rarity == 6" style="margin-left:12px;color: #d04d02">{{ v.name }}<span v-if="v.other.strengthening > 0">+{{ v.other.strengthening }}</span></span>
                                                        <span  v-else-if="v.other.rarity == 7" style="margin-left:12px;color: #e6ff02">{{ v.name }}<span v-if="v.other.strengthening > 0">+{{ v.other.strengthening }}</span></span>
                                            
                                                      </div>
                                                      <div>
                                                        <p style="text-align: right;margin-top:20px;color:#9195ff;">
                                                          <span  v-if="v.other.rarity == 0" style="color: #fff;">普通</span>
                                                          <span  v-else-if="v.other.rarity == 1" style="color: rgb(244 159 255);">高级</span>
                                                          <span  v-else-if="v.other.rarity == 2" style="color: #844693;">稀有</span>
                                                          <span  v-else-if="v.other.rarity == 3" style="color: rgb(225 0 255);">神器</span>
                                                          <span  v-else-if="v.other.rarity == 4" style="color: #dde4b6;">史诗</span>
                                                          <span  v-else-if="v.other.rarity == 5" style="color: #a7ad7a;">勇者</span>
                                                          <span  v-else-if="v.other.rarity == 6" style="color: #d04d02;">传说</span>
                                                          <span  v-else-if="v.other.rarity == 7" style="color: #e6ff02;">神话</span>
                                                        </p>
                                                      </div>
                                                     <div>
                                                  <p style="text-align: right;">
                                                    <span  style="color: #fff;" v-if="v.other.items_type == 10">武器</span>
                                                    <span  style="color: #fff;" v-if="v.other.items_type == 11">称号</span>
                                                    <span  style="color: #fff;" v-if="v.other.items_type == 12">上衣</span>
                                                    <span  style="color: #fff;" v-if="v.other.items_type == 13">头肩</span>
                                                    <span  style="color: #fff;" v-if="v.other.items_type == 14">下衣</span>
                                                    <span  style="color: #fff;" v-if="v.other.items_type == 15">鞋子</span>
                                                    <span  style="color: #fff;" v-if="v.other.items_type == 16">腰带</span>
                                                    <span  style="color: #fff;" v-if="v.other.items_type == 17">项链</span>
                                                    <span  style="color: #fff;" v-if="v.other.items_type == 18">手镯</span>
                                                    <span  style="color: #fff;" v-if="v.other.items_type == 19">戒指</span>
                                                    <span  style="color: #fff;" v-if="v.other.items_type == 20">辅助装备</span>
                                                    <span  style="color: #fff;" v-if="v.other.items_type == 21">魔法石</span>
                                                  </p>
                                                </div>
                                                <div v-if="inArray(v.other.rarity,[2,3,4,5,6,7,8,9])">
                                                  <p style="text-align: right;margin-top: 20px;">
                                                    <span  style="color: #fff;" v-if="v.other.seal_flag == 1" >{{SealFlag(v.other.strengthening)}}</span>
                                                    <span  style="color: rgb(206 9 9);" v-else-if="v.other.seal_flag == 0">无法交易</span>
                                                  </p>
                                                </div>
                                                <div>
                                                  <p>
                                                    <span  v-if="v.other.maxlevel > 0" style="color: #fff;">等级{{v.other.maxlevel}}以上使用</span>
                                                    <span  v-else  style="color: #fff;">等级不限制</span>
                                                    <span  v-if="v.other.num == 1" style="color: #fff;float: right;">最下级</span>
                                                    <span  v-if="v.other.num == 2 || v.other.num == 696817050" style="color: #fff;float: right;">上级</span>
                                                    <span  v-if="v.other.num == 3 || v.other.num == 1553487634" style="color: #fff;float: right;">下级</span>
                                                    <span  v-if="v.other.num == 4 || v.other.num == 1294422825" style="color: #fff;float: right;">最上级</span>
                                                  </p>
                                                </div>
                                                <div v-if="v.other.growth > 0">
                                                  <p style="text-align: left;margin-top: 20px;">
                                                    <span v-if="v.other.growth == 1"  style="color: rgb(239 24 255);">增幅：异次元体力 + {{v.other.growth_fj}}</span>
                                                    <span v-if="v.other.growth == 2"  style="color: rgb(239 24 255);">增幅：异次元精神 + {{v.other.growth_fj}}</span>
                                                    <span v-if="v.other.growth == 3"  style="color: rgb(239 24 255);">增幅：异次元力量 + {{v.other.growth_fj}}</span>
                                                    <span v-if="v.other.growth == 4"  style="color: rgb(239 24 255);">增幅：异次元智力 + {{v.other.growth_fj}}</span>
                                                  </p>
                                                </div>
                                                <div v-if="v.other.forging >0">
                                                  <p style="text-align: left;margin-top: 10px;">
                                                    <span  style="color: rgb(246 153 253);">锻造第{{v.other.forging}}阶段：独立攻击 +{{6.2*v.other.forging}}</span>
                                                  </p>
                                                </div>
                                                 <div v-if="v.other.extend_info > 0">
                                                  <p style="text-align: left;margin-top: 20px;">
                                                    <span   style="color:rgb(190 212 8);">附魔宝珠 : {{v.other.extend_name}}</span>
                                                  </p>
                                                </div>
                                                    </div>
                                                    <div v-if="v.type == 2">
                                                      <div>
                                                        <img v-if="v.ico_data != ''" :src="v.ico_data" style="width:28px;height:28px;">
                                                        <img v-else src="../../../public/static/plug/tian/static/images/6.png" style="width:28px;height:28px;">
                                                        <span  v-if="v.other.rarity == 0" style="margin-left:12px;color: #fff;">{{ v.name }}</span>
                                                        <span  v-else-if="v.other.rarity == 1" style="margin-left:12px;color: rgb(244 159 255)">{{ v.name }}</span>
                                                        <span v-else-if="v.other.rarity == 2" style="margin-left:12px;color: #844693">{{ v.name }}</span>
                                                        <span v-else-if="v.other.rarity == 3" style="margin-left:12px;color: rgb(225 0 255)">{{ v.name }}</span>
                                                        <span  v-else-if="v.other.rarity == 4" style="margin-left:12px;color: #dde4b6">{{ v.name }}</span>
                                                        <span  v-else-if="v.other.rarity == 5" style="margin-left:12px;color: #a7ad7a">{{ v.name }}</span>
                                                        <span  v-else-if="v.other.rarity == 6" style="margin-left:12px;color: #d04d02">{{ v.name }}</span>
                                                        <span  v-else-if="v.other.rarity == 7" style="margin-left:12px;color: #e6ff02">{{ v.name }}</span>
                                            
                                                      </div>
                                                      <div>
                                                        <p style="text-align: right;margin-top:20px;color:#9195ff;">
                                                          <span  v-if="v.other.rarity == 0" style="color: #fff;">普通</span>
                                                          <span  v-else-if="v.other.rarity == 1" style="color: rgb(244 159 255);">高级</span>
                                                          <span  v-else-if="v.other.rarity == 2" style="color: #844693;">稀有</span>
                                                          <span  v-else-if="v.other.rarity == 3" style="color: rgb(225 0 255);">神器</span>
                                                          <span  v-else-if="v.other.rarity == 4" style="color: #dde4b6;">史诗</span>
                                                          <span  v-else-if="v.other.rarity == 5" style="color: #a7ad7a;">勇者</span>
                                                          <span  v-else-if="v.other.rarity == 6" style="color: #d04d02;">传说</span>
                                                          <span  v-else-if="v.other.rarity == 7" style="color: #e6ff02;">神话</span>
                                                        </p>
                                                      </div>
                                                      <div>
                                                        <p>
                                                          <span v-if="v.type == 2">消耗品</span>
                                                          <span v-if="v.type == 3">材料</span>
                                                        </p>
                                                      </div>
                                                    
                                                    </div>
                                                    <div v-if="v.type == 3">
                                                      <div>
                                                        <img v-if="v.ico_data != ''" :src="v.ico_data" style="width:28px;height:28px;">
                                                        <img v-else src="../../../public/static/plug/tian/static/images/6.png" style="width:28px;height:28px;">
                                                        <span  v-if="v.other.rarity == 0" style="margin-left:12px;color: #fff;">{{ v.name }}</span>
                                                        <span  v-else-if="v.other.rarity == 1" style="margin-left:12px;color: rgb(244 159 255)">{{ v.name }}</span>
                                                        <span v-else-if="v.other.rarity == 2" style="margin-left:12px;color: #844693">{{ v.name }}</span>
                                                        <span v-else-if="v.other.rarity == 3" style="margin-left:12px;color: rgb(225 0 255)">{{ v.name }}</span>
                                                        <span  v-else-if="v.other.rarity == 4" style="margin-left:12px;color: #dde4b6">{{ v.name }}</span>
                                                        <span  v-else-if="v.other.rarity == 5" style="margin-left:12px;color: #a7ad7a">{{ v.name }}</span>
                                                        <span  v-else-if="v.other.rarity == 6" style="margin-left:12px;color: #d04d02">{{ v.name }}</span>
                                                        <span  v-else-if="v.other.rarity == 7" style="margin-left:12px;color: #e6ff02">{{ v.name }}</span>
                                            
                                                      </div>
                                                      <div>
                                                        <p style="text-align: right;margin-top:20px;color:#9195ff;">
                                                          <span  v-if="v.other.rarity == 0" style="color: #fff;">普通</span>
                                                          <span  v-else-if="v.other.rarity == 1" style="color: rgb(244 159 255);">高级</span>
                                                          <span  v-else-if="v.other.rarity == 2" style="color: #844693;">稀有</span>
                                                          <span  v-else-if="v.other.rarity == 3" style="color: rgb(225 0 255);">神器</span>
                                                          <span  v-else-if="v.other.rarity == 4" style="color: #dde4b6;">史诗</span>
                                                          <span  v-else-if="v.other.rarity == 5" style="color: #a7ad7a;">勇者</span>
                                                          <span  v-else-if="v.other.rarity == 6" style="color: #d04d02;">传说</span>
                                                          <span  v-else-if="v.other.rarity == 7" style="color: #e6ff02;">神话</span>
                                                        </p>
                                                      </div>
                                                      <div>
                                                        <p>
                                                          <span v-if="v.type == 2">消耗品</span>
                                                          <span v-if="v.type == 3">材料</span>
                                                        </p>
                                                      </div>
                                                    
                                                    </div>
                                                    <div v-if="v.type == 4">
                                                      <div>
                                                        <img src="../../../public/static/plug/tian/static/images/6.png" style="width:28px;height:28px;"/>
                                                        <span style="margin-left:12px;color:rgb(172 182 129);">{{ v.name }}</span>
                                                      </div>
                                                      <div>
                                                        <p style="margin-top:20px;color:#9195ff;" @click="CloudDiskCopy(v.cdk)"><span>卡密:{{ v.cdk }}</span></p>
                                                      </div>
                                                    </div>
                                                </div>
                                                <p style="margin-left:6px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;width: 190px;padding-right: 0px;margin-right: -170px;"><span v-if="v.type == 1" style="color:#f70000; ">(装备)</span><span v-else-if="v.type == 2" style="color:#f70000;">(消耗)</span><span v-else-if="v.type == 3" style="color:#f70000;">(材料)</span><span v-else-if="v.type == 4" style="color:#f70000;">(卡密)</span>
                                                <span v-if="(v.type == 1 || v.type == 2 || v.type == 3) && v.other.rarity == 0" style="color: #fff;">{{ v.name }}
                                                      <span v-if="v.type == 1 && v.other.strengthening > 0">+{{ v.other.strengthening }}</span>
                                                      <span v-else-if="v.type == 2 || v.type == 3">x{{ v.other.num }}</span>
                                                    </span>
                                                    <span v-else-if="(v.type == 1 || v.type == 2 || v.type == 3) && v.other.rarity == 1" style="color: rgb(244 159 255)">{{ v.name }}
                                                      <span v-if="v.type == 1 && v.other.strengthening > 0">+{{ v.other.strengthening }}</span>
                                                      <span v-else-if="v.type == 2 || v.type == 3">x{{ v.other.num }}</span>
                                                    </span>
                                                    <span v-else-if="(v.type == 1 || v.type == 2 || v.type == 3) && v.other.rarity == 2" style="color: #844693">{{ v.name }}
                                                      <span v-if="v.type == 1 && v.other.strengthening > 0">+{{ v.other.strengthening }}</span>
                                                      <span v-else-if="v.type == 2 || v.type == 3">x{{ v.other.num }}</span>
                                                    </span>
                                                    <span  v-else-if="(v.type == 1 || v.type == 2 || v.type == 3) && v.other.rarity == 3" style="color: rgb(225 0 255)">{{ v.name }}
                                                      <span v-if="v.type == 1 && v.other.strengthening > 0">+{{ v.other.strengthening }}</span>
                                                      <span v-else-if="v.type == 2 || v.type == 3">x{{ v.other.num }}</span>
                                                    </span>
                                                    <span v-else-if="(v.type == 1 || v.type == 2 || v.type == 3) && v.other.rarity == 4" style="color: #dde4b6">{{ v.name }}
                                                      <span v-if="v.type == 1 && v.other.strengthening > 0">+{{ v.other.strengthening }}</span>
                                                      <span v-else-if="v.type == 2 || v.type == 3">x{{ v.other.num }}</span>
                                                    </span>
                                                    <span  v-else-if="(v.type == 1 || v.type == 2 || v.type == 3) && v.other.rarity == 5" style="color: #a7ad7a">{{ v.name }}
                                                      <span v-if="v.type == 1 && v.other.strengthening > 0">+{{ v.other.strengthening }}</span>
                                                      <span v-else-if="v.type == 2 || v.type == 3">x{{ v.other.num }}</span>
                                                    </span>
                                                    <span  v-else-if="(v.type == 1 || v.type == 2 || v.type == 3) && v.other.rarity == 6" style="color: #d04d02">{{ v.name }}
                                                      <span v-if="v.type == 1 && v.other.strengthening > 0">+{{ v.other.strengthening }}</span>
                                                      <span v-else-if="v.type == 2 || v.type == 3">x{{ v.other.num }}</span>
                                                    </span>
                                                    <span  v-else-if="(v.type == 1 || v.type == 2 || v.type == 3) && v.other.rarity == 7" style="color: #e6ff02">{{ v.name }}
                                                      <span v-if="v.type == 1 && v.other.strengthening > 0">+{{ v.other.strengthening }}</span>
                                                      <span v-else-if="v.type == 2 || v.type == 3">x{{ v.other.num }}</span>
                                                    </span>
                                                    <span  v-else >{{ v.name }}</span>
                                                </p>
                                              </el-tooltip> 
                                              </td>
                                            <td>
                                              <p style="width: 127px;margin-right: -41px;">{{v.coin}}</p></td>
                                            <td>
                                              <button style="color: #e94b00;margin-right:2px;cursor:pointer;" @click="TianShelves(v)">下架</button>
                                            </td>
                                          </tr>
                                        </div>
                                        <div v-else
                                            style="margin-top:118px;text-align:center;">
                                          <span>无数据</span>
                                        </div>
                                      </table>
                                    </div>
                                </el-col>
                            </el-row>
                            <!-- 交易记录 -->
                            <el-row :gutter="12" v-if="category3 == 2"> 
                                <el-col :span="24" style="padding-left:0px;padding-right:0px;"> 
                                    <div>
                                      <div class="tr">
                                        <span class="th-a" style="width:170px;">交易物品</span><span v-if="info.config.source == 1" style="width:88px">集币</span><span v-if="info.config.source == 2" style="width:88px">点券</span><span v-if="info.config.source == 3" style="width:88px">代币券</span><span style="width:75px">交易类型</span><span style="width:67px;">交易状态</span>
                                      </div>
                                      <table style="background:#232323;height: 374px;">
                                        <div v-if="info.user.trading_log.length > 0">
                                          <tr class="tt"
                                              v-for="(v, i) in info.user.trading_log"
                                              :key="i">
                                            <td class="td-a" style="width:368px;">
                                              <el-tooltip class="item" effect="dark" content="" placement="top">
                                                <div slot="content" style="width:198px;">
                                                    <div v-if="v.type == 1">
                                                      <div>
                                                        <img v-if="v.ico_data != ''" :src="v.ico_data" style="width:28px;height:28px;">
                                                        <img v-else src="../../../public/static/plug/tian/static/images/6.png" style="width:28px;height:28px;">
                                                        <span  v-if="v.other.rarity == 0" style="margin-left:12px;color: #fff;">{{ v.name }}<span v-if="v.other.strengthening > 0">+{{ v.other.strengthening }}</span></span>
                                                        <span  v-else-if="v.other.rarity == 1" style="margin-left:12px;color: rgb(244 159 255)">{{ v.name }}<span v-if="v.other.strengthening > 0">+{{ v.other.strengthening }}</span></span>
                                                        <span v-else-if="v.other.rarity == 2" style="margin-left:12px;color: #844693">{{ v.name }}<span v-if="v.other.strengthening > 0">+{{ v.other.strengthening }}</span></span>
                                                        <span v-else-if="v.other.rarity == 3" style="margin-left:12px;color: rgb(225 0 255)">{{ v.name }}<span v-if="v.other.strengthening > 0">+{{ v.other.strengthening }}</span></span>
                                                        <span  v-else-if="v.other.rarity == 4" style="margin-left:12px;color: #dde4b6">{{ v.name }}<span v-if="v.other.strengthening > 0">+{{ v.other.strengthening }}</span></span>
                                                        <span  v-else-if="v.other.rarity == 5" style="margin-left:12px;color: #a7ad7a">{{ v.name }}<span v-if="v.other.strengthening > 0">+{{ v.other.strengthening }}</span></span>
                                                        <span  v-else-if="v.other.rarity == 6" style="margin-left:12px;color: #d04d02">{{ v.name }}<span v-if="v.other.strengthening > 0">+{{ v.other.strengthening }}</span></span>
                                                        <span  v-else-if="v.other.rarity == 7" style="margin-left:12px;color: #e6ff02">{{ v.name }}<span v-if="v.other.strengthening > 0">+{{ v.other.strengthening }}</span></span>
                                            
                                                      </div>
                                                      <div>
                                                        <p style="text-align: right;margin-top:20px;color:#9195ff;">
                                                          <span  v-if="v.other.rarity == 0" style="color: #fff;">普通</span>
                                                          <span  v-else-if="v.other.rarity == 1" style="color: rgb(244 159 255);">高级</span>
                                                          <span  v-else-if="v.other.rarity == 2" style="color: #844693;">稀有</span>
                                                          <span  v-else-if="v.other.rarity == 3" style="color: rgb(225 0 255);">神器</span>
                                                          <span  v-else-if="v.other.rarity == 4" style="color: #dde4b6;">史诗</span>
                                                          <span  v-else-if="v.other.rarity == 5" style="color: #a7ad7a;">勇者</span>
                                                          <span  v-else-if="v.other.rarity == 6" style="color: #d04d02;">传说</span>
                                                          <span  v-else-if="v.other.rarity == 7" style="color: #e6ff02;">神话</span>
                                                        </p>
                                                      </div>
                                                      <div>
                                                  <p style="text-align: right;">
                                                    <span  style="color: #fff;" v-if="v.other.items_type == 10">武器</span>
                                                    <span  style="color: #fff;" v-if="v.other.items_type == 11">称号</span>
                                                    <span  style="color: #fff;" v-if="v.other.items_type == 12">上衣</span>
                                                    <span  style="color: #fff;" v-if="v.other.items_type == 13">头肩</span>
                                                    <span  style="color: #fff;" v-if="v.other.items_type == 14">下衣</span>
                                                    <span  style="color: #fff;" v-if="v.other.items_type == 15">鞋子</span>
                                                    <span  style="color: #fff;" v-if="v.other.items_type == 16">腰带</span>
                                                    <span  style="color: #fff;" v-if="v.other.items_type == 17">项链</span>
                                                    <span  style="color: #fff;" v-if="v.other.items_type == 18">手镯</span>
                                                    <span  style="color: #fff;" v-if="v.other.items_type == 19">戒指</span>
                                                    <span  style="color: #fff;" v-if="v.other.items_type == 20">辅助装备</span>
                                                    <span  style="color: #fff;" v-if="v.other.items_type == 21">魔法石</span>
                                                  </p>
                                                </div>
                                                <div v-if="inArray(v.other.rarity,[2,3,4,5,6,7,8,9])">
                                                  <p style="text-align: right;margin-top: 20px;">
                                                    <span  style="color: #fff;" v-if="v.other.seal_flag == 1" >{{SealFlag(v.other.strengthening)}}</span>
                                                    <span  style="color: rgb(206 9 9);" v-else-if="v.other.seal_flag == 0">无法交易</span>
                                                  </p>
                                                </div>
                                                <div>
                                                  <p>
                                                    <span  v-if="v.other.maxlevel > 0" style="color: #fff;">等级{{v.other.maxlevel}}以上使用</span>
                                                    <span  v-else  style="color: #fff;">等级不限制</span>
                                                    <span  v-if="v.other.num == 1" style="color: #fff;float: right;">最下级</span>
                                                    <span  v-if="v.other.num == 2 || v.other.num == 696817050" style="color: #fff;float: right;">上级</span>
                                                    <span  v-if="v.other.num == 3 || v.other.num == 1553487634" style="color: #fff;float: right;">下级</span>
                                                    <span  v-if="v.other.num == 4 || v.other.num == 1294422825" style="color: #fff;float: right;">最上级</span>
                                                  </p>
                                                </div>
                                                <div v-if="v.other.growth > 0">
                                                  <p style="text-align: left;margin-top: 20px;">
                                                    <span v-if="v.other.growth == 1"  style="color: rgb(239 24 255);">增幅：异次元体力 + {{v.other.growth_fj}}</span>
                                                    <span v-if="v.other.growth == 2"  style="color: rgb(239 24 255);">增幅：异次元精神 + {{v.other.growth_fj}}</span>
                                                    <span v-if="v.other.growth == 3"  style="color: rgb(239 24 255);">增幅：异次元力量 + {{v.other.growth_fj}}</span>
                                                    <span v-if="v.other.growth == 4"  style="color: rgb(239 24 255);">增幅：异次元智力 + {{v.other.growth_fj}}</span>
                                                  </p>
                                                </div>
                                                <div v-if="v.other.forging >0">
                                                  <p style="text-align: left;margin-top: 10px;">
                                                    <span  style="color: rgb(246 153 253);">锻造第{{v.other.forging}}阶段：独立攻击 +{{6.2*v.other.forging}}</span>
                                                  </p>
                                                </div>
                                                 <div v-if="v.other.extend_info > 0">
                                                  <p style="text-align: left;margin-top: 20px;">
                                                    <span   style="color:rgb(190 212 8);">附魔宝珠 : {{v.other.extend_name}}</span>
                                                  </p>
                                                </div>
                                                      <div>
                                                        <p style="text-align: center;margin-top:20px;"><span style="color:rgb(112 110 116);">物品详情存在一定的数据差异，实际数据去自行到游戏中对比，物品购买后直接发送至您的邮箱中，请注意查收。</span></p>
                                                      </div>
                                                    </div>
                                                    <div v-if="v.type == 2">
                                                      <div>
                                                        <img v-if="v.ico_data != ''" :src="v.ico_data" style="width:28px;height:28px;">
                                                        <img v-else src="../../../public/static/plug/tian/static/images/6.png" style="width:28px;height:28px;">
                                                        <span  v-if="v.other.rarity == 0" style="margin-left:12px;color: #fff;">{{ v.name }}</span>
                                                        <span  v-else-if="v.other.rarity == 1" style="margin-left:12px;color: rgb(244 159 255)">{{ v.name }}</span>
                                                        <span v-else-if="v.other.rarity == 2" style="margin-left:12px;color: #844693">{{ v.name }}</span>
                                                        <span v-else-if="v.other.rarity == 3" style="margin-left:12px;color: rgb(225 0 255)">{{ v.name }}</span>
                                                        <span  v-else-if="v.other.rarity == 4" style="margin-left:12px;color: #dde4b6">{{ v.name }}</span>
                                                        <span  v-else-if="v.other.rarity == 5" style="margin-left:12px;color: #a7ad7a">{{ v.name }}</span>
                                                        <span  v-else-if="v.other.rarity == 6" style="margin-left:12px;color: #d04d02">{{ v.name }}</span>
                                                        <span  v-else-if="v.other.rarity == 7" style="margin-left:12px;color: #e6ff02">{{ v.name }}</span>
                                            
                                                      </div>
                                                      <div>
                                                        <p style="text-align: right;margin-top:20px;color:#9195ff;">
                                                          <span  v-if="v.other.rarity == 0" style="color: #fff;">普通</span>
                                                          <span  v-else-if="v.other.rarity == 1" style="color: rgb(244 159 255);">高级</span>
                                                          <span  v-else-if="v.other.rarity == 2" style="color: #844693;">稀有</span>
                                                          <span  v-else-if="v.other.rarity == 3" style="color: rgb(225 0 255);">神器</span>
                                                          <span  v-else-if="v.other.rarity == 4" style="color: #dde4b6;">史诗</span>
                                                          <span  v-else-if="v.other.rarity == 5" style="color: #a7ad7a;">勇者</span>
                                                          <span  v-else-if="v.other.rarity == 6" style="color: #d04d02;">传说</span>
                                                          <span  v-else-if="v.other.rarity == 7" style="color: #e6ff02;">神话</span>
                                                        </p>
                                                      </div>
                                                      <div>
                                                        <p>
                                                          <span v-if="v.type == 2">消耗品</span>
                                                          <span v-if="v.type == 3">材料</span>
                                                        </p>
                                                      </div>
                                                      <div>
                                                        <p style="text-align: center;margin-top:20px;"><span style="color:rgb(112 110 116);">物品详情存在一定的数据差异，实际数据去自行到游戏中对比，物品购买后直接发送至您的邮箱中，请注意查收。</span></p>
                                                      </div>
                                                    
                                                    </div>
                                                    <div v-if="v.type == 3">
                                                      <div>
                                                        <img v-if="v.ico_data != ''" :src="v.ico_data" style="width:28px;height:28px;">
                                                        <img v-else src="../../../public/static/plug/tian/static/images/6.png" style="width:28px;height:28px;">
                                                        <span  v-if="v.other.rarity == 0" style="margin-left:12px;color: #fff;">{{ v.name }}</span>
                                                        <span  v-else-if="v.other.rarity == 1" style="margin-left:12px;color: rgb(244 159 255)">{{ v.name }}</span>
                                                        <span v-else-if="v.other.rarity == 2" style="margin-left:12px;color: #844693">{{ v.name }}</span>
                                                        <span v-else-if="v.other.rarity == 3" style="margin-left:12px;color: rgb(225 0 255)">{{ v.name }}</span>
                                                        <span  v-else-if="v.other.rarity == 4" style="margin-left:12px;color: #dde4b6">{{ v.name }}</span>
                                                        <span  v-else-if="v.other.rarity == 5" style="margin-left:12px;color: #a7ad7a">{{ v.name }}</span>
                                                        <span  v-else-if="v.other.rarity == 6" style="margin-left:12px;color: #d04d02">{{ v.name }}</span>
                                                        <span  v-else-if="v.other.rarity == 7" style="margin-left:12px;color: #e6ff02">{{ v.name }}</span>
                                            
                                                      </div>
                                                      <div>
                                                        <p style="text-align: right;margin-top:20px;color:#9195ff;">
                                                          <span  v-if="v.other.rarity == 0" style="color: #fff;">普通</span>
                                                          <span  v-else-if="v.other.rarity == 1" style="color: rgb(244 159 255);">高级</span>
                                                          <span  v-else-if="v.other.rarity == 2" style="color: #844693;">稀有</span>
                                                          <span  v-else-if="v.other.rarity == 3" style="color: rgb(225 0 255);">神器</span>
                                                          <span  v-else-if="v.other.rarity == 4" style="color: #dde4b6;">史诗</span>
                                                          <span  v-else-if="v.other.rarity == 5" style="color: #a7ad7a;">勇者</span>
                                                          <span  v-else-if="v.other.rarity == 6" style="color: #d04d02;">传说</span>
                                                          <span  v-else-if="v.other.rarity == 7" style="color: #e6ff02;">神话</span>
                                                        </p>
                                                      </div>
                                                      <div>
                                                        <p>
                                                          <span v-if="v.type == 2">消耗品</span>
                                                          <span v-if="v.type == 3">材料</span>
                                                        </p>
                                                      </div>
                                                      <div>
                                                        <p style="text-align: center;margin-top:20px;"><span style="color:rgb(112 110 116);">物品详情存在一定的数据差异，实际数据去自行到游戏中对比，物品购买后直接发送至您的邮箱中，请注意查收。</span></p>
                                                      </div>
                                                    
                                                    </div>
                                                    <div v-if="v.type == 4">
                                                      <div>
                                                        <img src="../../../public/static/plug/tian/static/images/6.png" style="width:28px;height:28px;"/>
                                                        <span style="margin-left:12px;color:rgb(172 182 129);">{{ v.name }}</span>
                                                      </div>
                                                      <div>
                                                        <p style="margin-top:20px;color:#9195ff;" @click="CloudDiskCopy(v.cdk)"><span>卡密:{{ v.cdk }}</span></p>
                                                      </div>
                                                      <div>
                                                        <p v-if="v.gm_type == 1" style="text-align: center;margin-top:20px;"><span style="color:rgb(112 110 116);">请尽快复制到登录器上兑换，避免商家二次出售或使用等导致失效问题。</span></p>
                                                      </div>
                                                    </div>
                                                    <div v-if="v.type == 5">
                                                      <div>
                                                          <img :src="v.ioc" style="width:28px;height:28px;"/>
                                                          <span style="margin-left:12px;color:#d1ff0e;">{{ v.name }}</span>
                                                        </div>
                                                        <div>
                                                          <p style="text-align: right;margin-top:20px;color:#9195ff;"><span>礼包</span></p>
                                                        </div>
                                                        <div>
                                                          <p style="text-align: left;margin-top:10px;"><span style="color:#a179e2;">礼包内包含了以下内容</span></p>
                                                        </div>
                                                        <div  style="text-align: left;margin-top:10px;">
                                                          <p style="margin-bottom:6px;color:#a18989;" v-for="va, indx in v.code_data"  :key="indx">{{ va.name }}*{{ va.num }}</p>
                                                        </div>
                                                        <div>
                                                          <p style="text-align: center;margin-top:20px;"><span style="color:rgb(112 110 116);">购买后商品直接发送至当前角色邮箱中，请注意查收。</span></p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <p style="margin-left:6px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;width: 160px;padding-right: 0px;margin-right: -123px;"><span v-if="v.type == 1" style="color:rgb(165 63 152); ">(装备)</span><span v-else-if="v.type == 2" style="color:rgb(121 79 35);">(消耗)</span><span v-else-if="v.type == 3" style="color:#f70000;">(材料)</span><span v-else-if="v.type == 4" style="color:rgb(185 179 179);">(卡密)</span><span v-else-if="v.type == 5" style="color:rgb(109 210 18);">(商城)</span>
                                                    <span v-if="(v.type == 1 || v.type == 2 || v.type == 3) && v.other.rarity == 0" style="color: #fff;">{{ v.name }}
                                                      <span v-if="v.type == 1 && v.other.strengthening > 0">+{{ v.other.strengthening }}</span>
                                                      <span v-else-if="v.type == 2 || v.type == 3">x{{ v.other.num }}</span>
                                                    </span>
                                                    <span v-else-if="(v.type == 1 || v.type == 2 || v.type == 3) && v.other.rarity == 1" style="color: rgb(244 159 255)">{{ v.name }}
                                                      <span v-if="v.type == 1 && v.other.strengthening > 0">+{{ v.other.strengthening }}</span>
                                                      <span v-else-if="v.type == 2 || v.type == 3">x{{ v.other.num }}</span>
                                                    </span>
                                                    <span v-else-if="(v.type == 1 || v.type == 2 || v.type == 3) && v.other.rarity == 2" style="color: #844693">{{ v.name }}
                                                      <span v-if="v.type == 1 && v.other.strengthening > 0">+{{ v.other.strengthening }}</span>
                                                      <span v-else-if="v.type == 2 || v.type == 3">x{{ v.other.num }}</span>
                                                    </span>
                                                    <span  v-else-if="(v.type == 1 || v.type == 2 || v.type == 3) && v.other.rarity == 3" style="color: rgb(225 0 255)">{{ v.name }}
                                                      <span v-if="v.type == 1 && v.other.strengthening > 0">+{{ v.other.strengthening }}</span>
                                                      <span v-else-if="v.type == 2 || v.type == 3">x{{ v.other.num }}</span>
                                                    </span>
                                                    <span v-else-if="(v.type == 1 || v.type == 2 || v.type == 3) && v.other.rarity == 4" style="color: #dde4b6">{{ v.name }}
                                                      <span v-if="v.type == 1 && v.other.strengthening > 0">+{{ v.other.strengthening }}</span>
                                                      <span v-else-if="v.type == 2 || v.type == 3">x{{ v.other.num }}</span>
                                                    </span>
                                                    <span  v-else-if="(v.type == 1 || v.type == 2 || v.type == 3) && v.other.rarity == 5" style="color: #a7ad7a">{{ v.name }}
                                                      <span v-if="v.type == 1 && v.other.strengthening > 0">+{{ v.other.strengthening }}</span>
                                                      <span v-else-if="v.type == 2 || v.type == 3">x{{ v.other.num }}</span>
                                                    </span>
                                                    <span  v-else-if="(v.type == 1 || v.type == 2 || v.type == 3) && v.other.rarity == 6" style="color: #d04d02">{{ v.name }}
                                                      <span v-if="v.type == 1 && v.other.strengthening > 0">+{{ v.other.strengthening }}</span>
                                                      <span v-else-if="v.type == 2 || v.type == 3">x{{ v.other.num }}</span>
                                                    </span>
                                                    <span  v-else-if="(v.type == 1 || v.type == 2 || v.type == 3) && v.other.rarity == 7" style="color: #e6ff02">{{ v.name }}
                                                      <span v-if="v.type == 1 && v.other.strengthening > 0">+{{ v.other.strengthening }}</span>
                                                      <span v-else-if="v.type == 2 || v.type == 3">x{{ v.other.num }}</span>
                                                    </span>
                                                    <span  v-else>{{ v.name }}</span>
                                                </p>
                                              </el-tooltip> 
                                            </td>
                                            <td style="width:79px">
                                              <p style="width:100px;"><span v-if="v.gm_type == 1">-</span><span v-if="v.gm_type == 2">+</span>{{v.coin}}</p>
                                            </td>
                                            <td>
                                              <span v-if="v.gm_type == 1" style="color:#f00;">买入</span>
                                              <span v-if="v.gm_type == 2" style="color:#7fd224;">卖出</span>
                                            </td>
                                            <td>
                                              <span>完成<span v-if="v.type == 4 && v.gm_type == 1" style="color:#ff0101;" @click="CloudDiskCopy(v.cdk)">(卡密)</span> </span>
                                              <!-- <span v-if="v.gm_type == 2">完成</span> -->
                                              <!-- <span v-else-if="v.gm_type == 1 && v.delivery == 1">完成</span>
                                              <span v-else-if="v.gm_type == 1 && v.delivery == 0" style="color:#fd0000;">补发</span> -->
                                            </td>
                                          </tr>
                                        </div>
                                        <div v-else
                                            style="margin-top:118px;text-align:center;">
                                          <span>无数据</span>
                                        </div>
                                      </table>
                                    </div>
                                </el-col>
                            </el-row>
                            <!-- 提现记录 -->
                            <el-row  :gutter="12" v-if="category3 == 4 && info.config.withdrawal == 1">
                              <el-col :span="12"> 
                                  <div style="margin-top: 30px;margin-left: 2px;">
                                      <ul>
                                        <li style="margin-bottom:10px;">
                                          <span>收款方式：</span>
                                          <select v-model="info.user.collection.type" style="width:94px;" @change="ChooseType">
                                            <option :value="0">请选择</option>
                                            <option :value="1">支付宝</option>
                                          </select>
                                        </li>
                                        <li style="margin-bottom:10px;">
                                          <span>收款人名：</span>
                                          <input type="text" v-model="info.user.collection.name" style="border: 1px solid #767676;padding: 0px 4px;width: 84px;"/>
                                        </li>
                                        <li style="margin-bottom:10px;">
                                          <span>收款账户：</span>
                                          <input type="text" v-model="info.user.collection.account" style="border: 1px solid #767676;padding: 0px 4px;width: 84px;"/>
                                        </li>
                                      </ul>
                                  </div>
                                  <div style="text-align: center;margin-top: 16px;">
                                    <button class="shelves" @click="SaveCollection">保 存</button>
                                  </div>
                              </el-col>  
                              <el-col :span="12"> 
                                  <div style="margin-top: 30px;margin-left: 2px;">
                                      <ul>
                                        <li style="margin-bottom:10px;padding-top:6px;">
                                          <span>集币余额：</span>
                                          <span>{{ info.user.money }}</span><span>(1集币=1rmb)</span>
                                        </li>
                                        <li style="margin-bottom:10px;">
                                          <span>提出手续：</span>
                                          <span>{{ info.config.withdrawal_poundage }}%</span>
                                        </li>
                                        <li style="margin-bottom:10px;">
                                          <span>提出金额：</span>
                                          <input type="text" v-model="money" style="border: 1px solid #767676;padding: 0px 4px;width: 84px;"/>
                                        </li>
                                      </ul>
                                  </div>
                                  <div style="text-align: center;margin-top: 12px;">
                                    <button class="shelves" @click="Withdrawal">提 出</button>
                                  </div>
                              </el-col>  
                              <el-col :span="24" style="padding-left:0px;padding-right:0px;"> 
                                  <div style="margin-top: 10px;">
                                    <div class="tr">
                                      <span class="th-a">申请时间</span><span>金额</span><span>状态</span>
                                    </div>
                                    <table style="background:#232323;height: 222px;">
                                      <div v-if="info.user.withdrawal_data.length > 0">
                                        <tr class="tt" v-for="(v, i) in info.user.withdrawal_data" :key="i">
                                          <td class="td-a"><span style="margin-left:6px;">{{v.create_time}}</span></td>
                                          <td style="width:170px;">{{v.balance}}</td>
                                          <td>
                                            <button style="color: #e94b00;" v-if="v.state == 0">待审</button>
                                            <button style="color: #7a4c4c;" v-if="v.state == 1">已结</button>
                                            <button style="color: #000;" v-if="v.state == 2">驳回</button>
                                          </td>
                                        </tr>
                                      </div>
                                      <div v-else
                                          style="margin-top:118px;text-align:center;">
                                        <span>无数据</span>
                                      </div>
                                    </table>
                                  </div>
                              </el-col>
                            </el-row>

                            <!-- 集币充值 -->
                            <el-row  :gutter="12" v-if="category3 == 5 && info.config.source == 1">
                              <el-col :span="24"> 
                                  <div style="margin-top: 30px;margin-left: 2px;">
                                      <ul>
                                        <li style="margin-bottom:10px;">
                                          <span>购卡地址：</span>
                                          <span style="cursor:pointer;"  @click="CloudDiskCopy(info.config.pay_url )">{{ info.config.pay_url }}</span>
                                        </li>
                                        <li style="margin-bottom:10px;">
                                          <span>充值卡号：</span>
                                          <input type="text" v-model="card" style="border: 1px solid #767676;padding: 0px 4px;width: 326px;"/>
                                        </li>
                                      </ul>
                                  </div>
                                  <div style="text-align: center;margin-top: 16px;">
                                    <button class="shelves" @click="Pay">充 值</button>
                                  </div>
                              </el-col>  
                              <el-col :span="24" style="padding-left:0px;padding-right:0px;"> 
                                  <div style="margin-top: 10px;">
                                    <div class="tr">
                                      <span class="th-a">充值卡号</span><span>集币</span><span>时间</span>
                                    </div>
                                    <table style="background:#232323;height: 252px;">
                                      <div v-if="info.user.pay_log.length > 0">
                                        <tr class="tt" v-for="(v, i) in info.user.pay_log" :key="i">
                                          <td class="td-a" style="width: 250px;"><span style="margin-left:6px;">{{v.camilo}}</span></td>
                                          <td>{{v.money}}</td>
                                          <td>{{v.sy_time}}</td>
                                        </tr>
                                      </div>
                                      <div v-else
                                          style="margin-top:118px;text-align:center;">
                                        <span>无数据</span>
                                      </div>
                                    </table>
                                  </div>
                              </el-col>
                            </el-row>
                          </div>
                        </el-col>
                    </el-row>
                </div>
            </div>
            <div class="alert">
              <!-- 弹出框 -->
              <div class="alert_bg" v-if="MessageDisplay">
                  <div class="alert_main">
                      <div class="alert_title"><span>提示</span></div>
                      <div class="alert_con">
                      <div class="alert_com">
                          <span>{{ MessageMsg }}</span>
                          <div class="btn"
                              @click="CloseAlert">关闭</div>
                      </div>
                      </div>
                      <div class="alert_bottom"><span></span></div>
                  </div>
              </div>
              <div class="alert_bg" v-if="SuccessDisplay">
                  <div class="alert_main">
                      <div class="alert_title"><span>提示</span></div>
                      <div class="alert_con">
                      <div class="alert_com">
                          <span>{{ MessageMsg }}</span>
                          <div class="btn"
                              @click="SuccessAlert">关闭</div>
                      </div>
                      </div>
                      <div class="alert_bottom"><span></span></div>
                  </div>
              </div>
              <!-- 登陆框 -->
              <div class="alert_bg" v-if="LoginDisplay">
                  <div class="alert_main"
                      style="margin-top: 166px; width: 350px">
                      <div class="alert_title"><span>绑定|切换</span></div>
                      <div class="alert_con">
                      <div class="alert_com">
                          <p style="margin-bottom: 6px; display: flex">
                          <span style="padding: 0px 0px 0px 42px">游戏账号：</span><input type="text"
                                  v-model="login_info.username"
                                  class="service__input" />
                          </p>
                          <p style="margin-bottom: 6px; display: flex">
                          <span style="padding: 0px 0px 0px 42px">游戏密码：</span><input type="password"
                                  v-model="login_info.password"
                                  class="service__input" />
                          </p>
                          <p style="margin-bottom: 6px; display: flex">
                          <span style="padding: 0px 0px 0px 42px">绑定角色：</span>
                          <select v-model="login_info.role"
                                  style="width: 174px">
                              <option v-for="(itmec, i) in role_data"
                                      :key="i"
                                      :value="itmec.charac_no">
                              {{ itmec.charac_name }}
                              </option>
                          </select>
                          <span style="background: #7478e4;padding: 3px 10px;margin-left: 2px;border-radius: 4%;" @click="getRole">获取</span>
                          </p>
                          <div>
                          <span style="padding: 0px 0px; color: #f90000"><span style="display: inline-block; padding: 0px 0px">{{
                                                      LoginError
                                                      }}</span></span>
                          </div>
                          <div style="text-align: center; padding: 10px 0px">
                          <div class="btn"
                              @click="login"
                              style="margin: 0px 0px; display: inline-block">
                              绑定
                          </div>
                          <div class="btn"
                              @click="LoginDisplay = false"
                              style="margin: 0px 0px; display: inline-block">
                              取消
                          </div>
                          </div>
                      </div>
                      </div>
                      <div class="alert_bottom"><span></span></div>
                  </div>
              </div>
            </div>
        </div>
    </div>
  </template>
    
    
  <script>
  //import $ from "jquery";
  import Vue from "vue";
  
  export default {
    name: "Msh",
    data () {
      return {
        MessageDisplay: false, //弹出框显示
        Loading: false,
        SuccessDisplay: false,//成功弹出框
        LoginDisplay: false, //登陆弹出框
        category1:1,
        category2:1,
        category3:1,
        LoginError:'',
        info: {
          m_id:0,
          charac_no:0,
          charac_name: '', //当前绑定角色
          config:{
            open:[],
            source:1,
            pay_url:'',
            withdrawal:0,
            withdrawal_min_money:0,
            withdrawal_poundage:0,
          },
          user:{
            collection:{
              type:0,
              name:'',
              account:'',
            },
            money:0,
            withdrawal_data:[],
            intheshelf:[],
            pay_log:[],
            trading_log:[],
          },
          items:{
            equipment:[],
            consumables:[],
            material:[],
            cdk:[],
            shop:[],
            buylog:[],
          },
        },
        login_info: {
          username: "",
          password: "",
          role: "",
        },
        form_add:{
          type:0,//上架类型
          postal_id:'',//邮件ID
          cdk:'',//要出售的CDK
          num:10000,//出售数量
          coin:1,
        },
        money:10,
        //临时
        llss:{
          sj_data:[
            {name:'1'},
            {name:'2'},
            {name:'3'},
            {name:'4'},
            {name:'5'},
            {name:'6'},
            {name:'7'},
            {name:'8'},
            {name:'9'},
            {name:'10'},
            {name:'11'},
            {name:'12'},
            {name:'12'},
            {name:'12'},
          ],
        },
        role_data:[],
        card:'',
      };
    },
    created () {
      this.getInfo();
    },
    methods: {
      //类型选择
      ChooseType(type){
        console.log(type);
      },
      //登陆
      login () {
        if (this.Loading == true) {
          return false;
        }
        this.Loading = true;
        this.axios({
          // 默认请求方式为get
          method: "post",
          url: Vue.prototype.config.webplugurl + "/PlugLogin",
          // 传递参数
          data: {
            key: this.$route.query.key,
            keyid:this.$route.query.id,
            username: this.login_info.username,
            password: this.login_info.password,
            role: this.login_info.role,
          },
          responseType: "json",
        })
          .then((response) => {
            this.Loading = false;
            if (response.data.code == 200) {
              this.LoginDisplay = false;
              this.getInfo();
            } else {
              this.LoginError = response.data.msg;
            }
          })
          .catch((error) => {
            this.Loading = false;
            // 请求失败，
            console.log(error);
          });
      },
      //获取角色
      getRole () {
        if (this.Loading == true) {
          return false;
        }
        this.Loading = true;
        this.axios({
          // 默认请求方式为get
          method: "post",
          url: Vue.prototype.config.webplugurl + "/getRole",
          // 传递参数
          data: {
            key: this.$route.query.key,
            keyid:this.$route.query.id,
            username: this.login_info.username,
            password: this.login_info.password,
          },
          responseType: "json",
        })
          .then((response) => {
            this.Loading = false;
            if (response.data.code == 200) {
              this.LoginError = "";
              this.role_data = response.data.info;
            } else {
              this.LoginError = response.data.msg;
            }
          })
          .catch((error) => {
            this.Loading = false;
            // 请求失败，
            console.log(error);
          });
      },
      //获取基本配置信息
      getInfo () {
        this.Loading = true;
        this.axios({
          // 默认请求方式为get
          method: "post",
          url: Vue.prototype.config.webplugurl + "/tian/getTianInfo",
          // 传递参数
          data: {
            key: this.$route.query.key,
            keyid:this.$route.query.id,
          },
          responseType: "json",
        })
          .then((response) => {
            this.Loading = false;
            if (response.data.code == 200) {
              this.info = response.data.info;
              if(response.data.info.config.open.length > 0){
                this.category2 = response.data.info.config.open[0];
              }
            } else {
              this.PopMessage(true, response.data.msg);
            }
          })
          .catch((error) => {
            this.Loading = false;
            // 请求失败，
            console.log(error);
          });
      },
      //提现
      Withdrawal(){
        if(this.Loading == true){
          return false;
        }
        this.Loading = true;
        this.axios({
          // 默认请求方式为get
          method: "post",
          url: Vue.prototype.config.webplugurl + "/tian/TianWithdrawal",
          // 传递参数
          data: {
            key: this.$route.query.key,
            keyid:this.$route.query.id,
            money:this.money,
          },
          responseType: "json",
        })
          .then((response) => {
            this.Loading = false;
            if (response.data.code == 200) {
                this.PopMessage(true, response.data.msg);
            } else {
                this.PopMessage(false, response.data.msg);
            }
          })
          .catch((error) => {
            this.Loading = false;
            // 请求失败，
            console.log(error);
          });
      },
      //保存收款
      SaveCollection(){
        if(this.Loading == true){
          return false;
        }
        this.Loading = true;
        this.axios({
          // 默认请求方式为get
          method: "post",
          url: Vue.prototype.config.webplugurl + "/tian/TianSaveCollection",
          // 传递参数
          data: {
            key: this.$route.query.key,
            keyid:this.$route.query.id,
            type:this.info.user.collection.type,
            name:this.info.user.collection.name,
            account:this.info.user.collection.account,
          },
          responseType: "json",
        })
          .then((response) => {
            this.Loading = false;
            if (response.data.code == 200) {
                this.PopMessage(true, response.data.msg);
            } else {
                this.PopMessage(false, response.data.msg);
            }
          })
          .catch((error) => {
            this.Loading = false;
            // 请求失败，
            console.log(error);
          });
      },
      Pay(){
        if(this.Loading == true){
          return false;
        }
        this.Loading = true;
        this.axios({
          // 默认请求方式为get
          method: "post",
          url: Vue.prototype.config.webplugurl + "/tian/TianPay",
          // 传递参数
          data: {
            key: this.$route.query.key,
            keyid:this.$route.query.id,
            card:this.card,
          },
          responseType: "json",
        })
          .then((response) => {
            this.Loading = false;
            if (response.data.code == 200) {
                this.PopMessage(true, response.data.msg);
            } else {
                this.PopMessage(false, response.data.msg);
            }
          })
          .catch((error) => {
            this.Loading = false;
            // 请求失败，
            console.log(error);
          });
      },
      //购买物品
      BuyItmes(type,id){
        if(this.Loading == true){
          return false;
        }
        this.Loading = true;
        this.axios({
          // 默认请求方式为get
          method: "post",
          url: Vue.prototype.config.webplugurl + "/tian/TianBuyItmes",
          // 传递参数
          data: {
            key: this.$route.query.key,
            keyid:this.$route.query.id,
            type:type,
            id:id,
          },
          responseType: "json",
        })
          .then((response) => {
            this.Loading = false;
            if (response.data.code == 200) {
                this.PopMessage(true, response.data.msg);
            } else {
                this.PopMessage(false, response.data.msg);
            }
          })
          .catch((error) => {
            this.Loading = false;
            // 请求失败，
            console.log(error);
          });
      },
      //上架物品
      AddShop(){
        if(this.Loading == true){
          return false;
        }
        this.Loading = true;
        this.axios({
          // 默认请求方式为get
          method: "post",
          url: Vue.prototype.config.webplugurl + "/tian/AddTianShop",
          // 传递参数
          data: {
            key: this.$route.query.key,
            keyid:this.$route.query.id,
            type:this.form_add.type,
            postal_id:this.form_add.postal_id,
            cdk:this.form_add.cdk,
            coin:this.form_add.coin,

          },
          responseType: "json",
        })
          .then((response) => {
            this.Loading = false;
            if (response.data.code == 200) {
                this.PopMessage(true, response.data.msg);
            } else {
                this.PopMessage(false, response.data.msg);
            }
          })
          .catch((error) => {
            this.Loading = false;
            // 请求失败，
            console.log(error);
          });
      },
      //下架物品
      TianShelves(vars){
        if(this.Loading == true){
          return false;
        }
        this.Loading = true;
        this.axios({
          // 默认请求方式为get
          method: "post",
          url: Vue.prototype.config.webplugurl + "/tian/TianShelves",
          // 传递参数
          data: {
            key: this.$route.query.key,
            keyid:this.$route.query.id,
            id:vars.id,
            type:vars.type,
            cdk:vars.cdk,
          },
          responseType: "json",
        })
          .then((response) => {
            this.Loading = false;
            if (response.data.code == 200) {
                this.PopMessage(true, response.data.msg);
            } else {
                this.PopMessage(false, response.data.msg);
            }
          })
          .catch((error) => {
            this.Loading = false;
            // 请求失败，
            console.log(error);
          });
      },
      //请求上架仓库
      TianItmesShelvesWarehouse(){
        if(this.Loading == true){
          return false;
        }
        this.Loading = true;
        this.axios({
          // 默认请求方式为get
          method: "post",
          url: Vue.prototype.config.webplugurl + "/tian/TianItmesShelvesWarehouse",
          // 传递参数
          data: {
            key: this.$route.query.key,
            keyid:this.$route.query.id,
          },
          responseType: "json",
        })
          .then((response) => {
            this.Loading = false;
            if (response.data.code == 200) {
                this.PopMessage(true, response.data.msg);
            } else {
                this.PopMessage(false, response.data.msg);
            }
          })
          .catch((error) => {
            this.Loading = false;
            // 请求失败，
            console.log(error);
          });
      },
       //弹出框状态
       PopMessage (type, msg) {
            this.SuccessDisplay = false;
            this.MessageDisplay = false;
            this.MessageMsg = msg;
            if (type == false) {
            this.MessageDisplay = true;
            } else {
            this.SuccessDisplay = true;
            }
        },
        SuccessAlert () {
            this.SuccessDisplay = false;
            this.MessageDisplay = false;
            this.getInfo();
        },
        //关闭窗口
        CloseAlert () {
            this.SuccessDisplay = false;
            this.MessageDisplay = false;
        },
        inArray(item, array) {
          return array.indexOf(item) !== -1
        },
        //把时间戳转日期
        formatDate(time) {
          const date = new Date(time)
          return date.toLocaleString()
        },
        SealFlag(Le){
          var name = "封装";
          if(Le >= 32 && Le < 64){
            name = "再封装(1次)"
          }else if (Le >= 64 && Le < 96){
            name = "再封装(2次)"
          }else if (Le >= 96 && Le < 128){
             name = "再封装(3次)"
          }else if (Le >= 128 && Le < 160){
            name = "再封装(4次)"
          }else if (Le >= 160 && Le < 192){
             name = "再封装(5次)"
          }else if (Le >= 192 && Le < 224){
            name = "再封装(6次)"
          }else if (Le >= 224 && Le < 256){
             name = "再封装(7次)"
          }else if (Le >= 256 && Le < 288){
            name = "再封装(8次)"
          }else if (Le >= 288 && Le < 320){
            name = "再封装(9次)"
          }
          return name
        },
        CloudDiskCopy(url) {
          console.log(url);
            //创建input标签
            var input = document.createElement('input')
            //将input的值设置为需要复制的内容
            input.value = url
            //添加input标签
            document.body.appendChild(input)
            //选中input标签
            input.select()
            //执行复制
            document.execCommand('copy')
            //成功提示信息
            this.PopMessage(false, '复制成功');
            //移除input标签
            document.body.removeChild(input)
          },
    },
  };
  </script>
    <style scoped>
  @import "../../../public/static/plug/tian/static/css/game.css";
  </style>

<style lang="less">
.task-title{
   width: 144px;
    float: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: clip;
}
.itme-title{
   width: 142px;
    float: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: clip;
}
.el-row{
    margin-left: 0px  !important;
    margin-right: 0px  !important;
}
</style>

<style>
  #root,
  body,
  html {
    min-width: 0px;
  }
  .el-cascader-node {
      height:20px !important;   
  }
  
  .el-cascader__dropdown{
    background: #101010  !important;
    border: 1px solid #616161  !important;
  }
  </style>